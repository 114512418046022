import { TrackingConstant } from 'client/tracking/constant';

export const APPRAISE_BY_TAB_HEADERS_CLASSNAME = 'appraise-by-tabs-headers';

// Expected to be uppercase for case-insensitive matching baseline:
export const APPRAISAL_QUERY_TAB_MMY = 'MMY';
export const APPRAISAL_QUERY_TAB_LICENSE_PLATE = 'LP';
export const VALID_APPRAISAL_QUERY_TAB_VALUES = [APPRAISAL_QUERY_TAB_MMY, APPRAISAL_QUERY_TAB_LICENSE_PLATE];

// Some fields will be changed in constructor(), for /sell-car page
export const APPRAISE_BY_VIN_TAB_EVENT_DATA = {
  action_category: TrackingConstant.USER_ACTION_CATEGORY,
  action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
  action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
};
