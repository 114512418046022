import { CmsModel } from 'client/data/models/cms';
import { withMetrics } from 'client/data/api/api-metrics';
import { EdmundsAPI } from 'client/data/api/api-client';
import { isArray, omitBy, isEmpty } from 'lodash';
import { parseDate } from 'client/data/cms/content';
import { marked } from 'marked'; // eslint-disable-line import/extensions
// the above is to disable eslint error: Missing file extension "cjs" for "marked"  import/extensions
// eslint's complaint here is not valid because
// 1. on server side this import resolves to marked/lib/marked.cjs, on client it resolves to marked/lib/marked.esm.js, eslint is not aware of this
// 2. we should never import a cjs file in any isomorphic module cause cjs is only for node.

/**
 * @param {String} feedPath
 * @returns {String} a normalized feed path
 */
function normalizeFeedPath(feedPath) {
  return feedPath.replace(/^(\/)*/, '/');
}

const SKIP_COMPONENT_STATES = new Set(['experiment', 'new', 'darklaunch']);

function validateEntry(entry, cdDate) {
  const { contentMetadata = {} } = entry;
  const { featureFlag, componentState, startDateISOFormat, endDateISOFormat } = contentMetadata;

  if (featureFlag) {
    return false;
  }

  if (componentState && SKIP_COMPONENT_STATES.has(componentState.toLowerCase())) {
    return false;
  }

  if (startDateISOFormat && cdDate < parseDate(startDateISOFormat)) {
    return false;
  }

  if (endDateISOFormat && cdDate >= parseDate(endDateISOFormat)) {
    return false;
  }

  // Check if apiResponse returns error message
  if (entry.message) {
    return false;
  }

  return true;
}

function reduceEntries(entries, cdDate) {
  if (isArray(entries)) {
    return entries.reduce((validEntries, entry) => {
      if (validateEntry(entry, cdDate)) {
        /* Standardizing Output of Editorial Content Via the Model
            See MR Note for context: https://gitlab.shared-services.accounts.edmunds.com/edmunds/node-site-venom/merge_requests/2486/#note_163654

            1. The main content to display for editorial items will be delivered through entry.content.
            2. The added/modified entry.content attribute will populate based on the existence of
              data for the following items (precedence is marked by the order shown):
                entry.contentMetadata.markdownContent,
                entry.contentMetaData.htmlContent,
                entry.content
            3. Metadata fields will be left intact for the time being to provide backward compatibility.
        */
        let content = entry.content;
        if (entry.contentMetadata) {
          if (entry.contentMetadata.markdownContent) {
            content = marked.parse(entry.contentMetadata.markdownContent);
          } else if (entry.contentMetadata.htmlContent) {
            content = entry.contentMetadata.htmlContent;
          }
        }
        return [
          ...validEntries,
          omitBy(
            {
              ...entry,
              content,
              childEntries: reduceEntries(entry.childEntries, cdDate),
            },
            isEmpty
          ),
        ];
      }

      return validEntries;
    }, []);
  }

  return [];
}

function reduceFeed(feed, cdDate) {
  if (validateEntry(feed, cdDate)) {
    const result = {
      ...omitBy(feed, isEmpty),
      childEntries: reduceEntries(feed.entries, cdDate),
    };

    delete result.entries;
    return result;
  }

  return {};
}

async function fetchContentFeed(feedPath, context, { cdDate = Date.now() }) {
  const url = `/editorial/v3/content/?path=${feedPath}&ispreview=true&tree=true&externalfetch=true&excludeTranscripts=true`;

  return withMetrics(EdmundsAPI, context)
    .fetchJson(url)
    .then(feed => reduceFeed(feed, cdDate));
}

export async function fetchContent(feedPath, context) {
  const cdDate = await context.resolveValue('cdDate', CmsModel);

  const normalizedFeedPath = normalizeFeedPath(feedPath);
  return fetchContentFeed(normalizedFeedPath, context, { cdDate });
}

export function fetchContentWithContent(feedPath, context) {
  return fetchContent(`${feedPath}&fetchcontents=true`, context);
}
