import PropTypes from 'prop-types';
import { isEmpty, round, get, keyBy, pickBy, identity, omitBy, isNil } from 'lodash';
import uuidv5 from 'uuid/v5';
import uuidv4 from 'uuid/v4';
import { EdmundsAPI, EdmundsPartnerOfferAPI } from 'client/data/api/api-client';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import {
  CONDITIONS,
  PARTNER_OFFER_TRACKING_CREATIVE_ID,
  APPRAISAL_PAGE_CREATIVE_ID,
  TRACKING_VALUES,
  LICENSE_PLATE_AND_VIN_CREATIVE_ID,
  PARTNER_OFFER_NO_INSTANT_OFFER_TRACKING_CREATIVE_ID,
  PARTNER_OFFER_ADDRESS_LOOKUP,
  EPO_UUIDV5_NAMESPACE,
  PARTNER_OFFER_ESTIMATED_OFFER_CREATIVE_ID,
  PARTNER_OFFER_APPOINTMENT_DRAWER_CREATIVE_ID,
  INELIGIBILITY_REASONS,
  APPRAISAL_OFFER_RENEWAL_CREATIVE_ID,
  EPO_OFFER_TRACKING,
  OFFER_CODE_TRACKING,
  EPO_OFFER_VIN_TRACKING,
  DECLINE_REASON_VALUE_TRACKING,
} from 'site-modules/shared/constants/appraisal/appraisal';
import { ESTIMATED_OFFER_DATA_KEYS } from 'site-modules/shared/constants/appraisal/estimate';
import { PARTNERS } from 'site-modules/shared/constants/multi-offer/partners';
import { getSquishVIN } from 'site-modules/shared/utils/vin-utils';
import { withMetrics } from 'client/data/api/api-metrics';
import { fillInPartner, transformQuestions } from 'client/data/transforms/appraisal/transform-questions';
import { getQuery } from 'client/utils/location';
import { HTTP_NO_CONTENT, HTTP_BAD_REQUEST } from 'client/utils/http-status';
import { getSyncDate } from 'site-modules/shared/components/profile/idm/idm-utils';
import { getDifferenceInDays } from 'site-modules/shared/utils/date-utils';

import { VehicleModel, buildUsedTmvPricingWithOptionsPath, buildOptionWithOemCodeStylePath } from './vehicle';
import { VehicleVinModel, buildStylesBasicPathFromSquishVin, VehicleVinEntities } from './vehicle-vin';
import {
  MultiOfferModel,
  searchByVinPath,
  prequalificationPath,
  modsRecordPath,
  createModsRecord,
  offerRenewalModsDataPath,
} from './multi-offer';
import { VisitorModel } from './visitor';
import { PageModel } from './page';

export const VENOM_X_PRODUCT_ID_HEADER = { 'X-Product-Id': 'venom' };

export const SMS_MESSAGE_TYPE = {
  EMO_1667: 'EMO-1667',
};

const VIN_ELIGIBILITY_INVALID_VIN = 'invalid-vin';

export const getVehicleInfoFromModsData = ({
  vehicle,
  kmxConditionQuestions,
  carwiserConditionQuestions,
  offerFinalizedAt,
  seller,
}) => ({
  vin: vehicle.vin,
  styleId: vehicle.edmundsStyleId,
  mileage: vehicle.mileage,
  condition: vehicle.condition,
  transmission: vehicle.transmissionType,
  exteriorColor: vehicle.exteriorColor,
  interiorColor: vehicle.interiorColor,
  selectedOptions: vehicle.selectedOptions,
  conditionQuestionsResponses: {
    kmx: kmxConditionQuestions.map(({ id, answers }) => ({
      id: +id,
      answers: answers.map(({ id: answerId }) => +answerId),
    })),
    // do NOT need to transform the IDs into integers for Carwiser, because they are EXPECTED to be strings.
    carwiser: carwiserConditionQuestions.map(({ id, answers }) => ({
      id,
      answers: answers.map(({ id: answerId }) => answerId),
    })),
  },
  offerFinalizedAt,
  source: 'MODS',
  abTestBucketing: seller.abTestBucketing,
});

export const calculatePrepopulatedMileage = lastQuote => {
  const createdDateUtc = new Date(lastQuote.createdDateUtc);
  const daysPast = getDifferenceInDays(createdDateUtc);
  let currentMileage = lastQuote.mileage;
  if (daysPast >= 30 && daysPast < 60) {
    currentMileage += 1000;
  } else if (daysPast >= 60) {
    currentMileage += 2000;
  }
  return currentMileage;
};

export const getVinEligibilityCheckEventData = () => ({
  action_name: TrackingConstant.ACTION_RECEIVE_EPO,
  action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
  action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
  subaction_name: TrackingConstant.SUBACTION_EPO_VIN_ELIGIBILITY_CHECK,
});

export const getYmmzEligibilityCheckEventData = creativeId => ({
  creative_id: creativeId,
  action_name: TrackingConstant.ACTION_RECEIVE_EPO,
  action_category: TrackingConstant.USER_ACTION_CATEGORY,
  action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
  subaction_name: TrackingConstant.SUBACTION_EPO_MMY_ZIP_ELIGIBILITY_CHECK,
});

const getMmyZipEligibilityMessageTrackingValue = message =>
  `${TRACKING_VALUES.mmy_zip_eligible}${
    message
      ? `_${message
          .toLowerCase()
          .replace(/\s|-/g, '_')
          .replace(/\W/g, '')}`
      : ''
  }`;

const fireEstimatedOffer = (value, subaction) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      subaction_name: subaction,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: PARTNER_OFFER_TRACKING_CREATIVE_ID,
      value,
    },
  });
};

export const fireEstimatedOfferRange = (min, max, subaction) => {
  fireEstimatedOffer(`${min} - ${max}`, subaction);
  fireEstimatedOffer(String(min), `${subaction}_min_value`);
  fireEstimatedOffer(String(max), `${subaction}_max_value`);
};

const roundValuation = ({ valuation }) => (valuation ? round(valuation, -2) : valuation);

export const getEstimateValues = estimateByCategory => ({
  minEstimate: get(estimateByCategory, [ESTIMATED_OFFER_DATA_KEYS.BELOW_AVERAGE, ESTIMATED_OFFER_DATA_KEYS.ESTIMATE]),
  maxEstimate: get(estimateByCategory, [ESTIMATED_OFFER_DATA_KEYS.EXCELLENT, ESTIMATED_OFFER_DATA_KEYS.ESTIMATE]),
});

/**
 * Method to make a call to send SMS.
 * @param {String} phoneNumber - The phone number to send SMS.
 * @param {String} url - The offer redemption certificate uri
 */
export const sendOfferSms = ({ phoneNumber, url, location = {}, messageData }) => {
  const messageType = getQuery(location).messageType;
  const fullPayload =
    messageType === 'happy-path-mock'
      ? {
          messageType,
        }
      : {
          messageType: SMS_MESSAGE_TYPE.EMO_1667,
          to: phoneNumber,
          shortUrl: {
            ttl: '7d',
            url,
          },
          messageData,
        };

  return EdmundsPartnerOfferAPI.fetchJson('/sms/messages', {
    headers: { ...VENOM_X_PRODUCT_ID_HEADER, 'content-type': 'application/json' },
    retries: 0,
    method: 'POST',
    body: JSON.stringify(omitBy(fullPayload, isNil)),
  });
};

/**
 * Determines whether it should trigger QUOTES PATCH call for Instant Offers when all the following exist:
 * 1. SKU
 * 2. QUOTE I.D.
 * 3. Eligible
 *
 * @returns {Boolean}
 */
export const isOfferAbleToSubmit = ({ isPartnerOfferEligibleYmmz, isPartnerOfferEligibleVin, quoteId, sku }) =>
  sku && quoteId && (isPartnerOfferEligibleVin || isPartnerOfferEligibleYmmz);

export const submitAppointment = async payload => {
  let response;

  try {
    response = await EdmundsPartnerOfferAPI.fetchJson(`/v2/appointments`, {
      headers: {
        'content-type': 'application/json',
        ...VENOM_X_PRODUCT_ID_HEADER,
      },
      retries: 1,
      method: 'POST',
      body: JSON.stringify(payload),
    });
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_name: TrackingConstant.ACTION_RECEIVE_EPO,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        subaction_name: TrackingConstant.SCHEDULE_APPOINTMENT,
        creative_id: PARTNER_OFFER_APPOINTMENT_DRAWER_CREATIVE_ID,
        value: 'success',
      },
    });
  } catch (e) {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_name: TrackingConstant.ACTION_RECEIVE_EPO,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        subaction_name: TrackingConstant.SCHEDULE_APPOINTMENT,
        creative_id: PARTNER_OFFER_APPOINTMENT_DRAWER_CREATIVE_ID,
        value: `${e.status}_ERROR`,
      },
    });
    response = { isError: true };
  }

  return response;
};

/**
 * Helper function that builds the data to be passed into the submitPartnerOfferAppraisal function below.
 * IMPORTANT: All metadata values are EXPECTED to be strings
 *
 * @param {Object} - object with all the required data.
 * @param {function} hashPartnerAppraisalData function to hash 'clientIp'; it's required if 'clientIp' is present.
 */
export const buildDataToSubmitPartnerOffer = (
  {
    conditionPricing,
    vin,
    condition,
    mileage,
    zipCode,
    transmission,
    exteriorColorCode,
    conditionQuestions,
    quoteId,
    isPartnerOfferEligibleVin,
    drive,
    sku,
    availableOptions,
    standardOptions,
    metadata = {},
    clientIp,
    hasTmvError,
  },
  hashPartnerAppraisalData
) => {
  let mileageInt = mileage;
  if (typeof mileage === 'string') {
    mileageInt = parseInt(mileage, 10);
  }

  const createdDateUtc = new Date().toISOString();
  const sourceId = uuidv5(`${mileageInt}|${vin}|${createdDateUtc}`, EPO_UUIDV5_NAMESPACE);

  const resultMetadata = {
    ...(metadata.interior_color ? { interior_color: metadata.interior_color } : {}),
    edmunds_style_id: metadata.edmunds_style_id,
    sem_campaign: metadata.sem_campaign,
    remote_host_request_id: 'EVAL-1950',
    remote_host_client_id: clientIp ? hashPartnerAppraisalData(clientIp) : '',
  };
  Object.keys(metadata).forEach(key => {
    if (key.startsWith('edmunds')) {
      resultMetadata[key] = metadata[key];
    }
  });

  return {
    conditionPricing,
    sourceId,
    createdDateUtc,
    vin,
    condition,
    mileage: mileageInt,
    zipCode,
    transmission,
    exteriorColorCode,
    conditionQuestions,
    quoteId,
    isPartnerOfferEligibleVin,
    visitorId: null,
    ciamId: null,
    drive,
    sku,
    availableOptions,
    standardOptions,
    metadata: resultMetadata,
    hasTmvError,
  };
};

/**
 * Makes offer call and returns response data with tracking fired.
 * IMPORTANT: please consider using the {@link obtainCarmaxOfferAndStoreIntoMods} function instead.
 *
 * @param {Object} dataToPost - REQUIRED (see buildDataToSubmitPartnerOffer function above).
 * @param {Object} creativeIds - OPTIONAL, lets the caller override the tracking creative ids used.
 * @param {Object} options - OPTIONAL, lets the caller configure the API call.
 * @returns {Object} offer data from API
 * @deprecated use {@link obtainCarmaxOfferAndStoreIntoMods} instead.
 */
export const submitPartnerOfferAppraisal = async (dataToPost, creativeIds, options = {}) => {
  const {
    vin,
    condition = '',
    conditionPricing,
    sku,
    quoteId,
    isPartnerOfferEligibleVin,
    hasTmvError,
    // all other props included
    ...otherProps
  } = dataToPost;
  if (!vin) {
    throw new Error("Invalid VIN can't be submitted for partner offer appraisal");
  }
  const vinInUpperCase = vin.toUpperCase();

  /**
   * Do not trigger QUOTES PATCH call when the offer meets the criteria for unable to submit.
   * @returns {Object} Empty object to render No Offer
   */
  if (!isOfferAbleToSubmit({ isPartnerOfferEligibleVin, sku, quoteId })) {
    fireEstimatedOffer(TrackingConstant.ZERO_VALUATION, TrackingConstant.SUBACTION_OFFER_ESTIMATE_PATCH);
    return {};
  }

  const body = JSON.stringify({
    styleCode: sku,
    vin: vinInUpperCase,
    ...otherProps,
  });
  let response;
  let errorStatus;
  try {
    response = await EdmundsPartnerOfferAPI.fetchJson(`/v2/quotes/${quoteId}`, {
      headers: {
        ...options.headers,
        'content-type': 'application/json',
        ...VENOM_X_PRODUCT_ID_HEADER,
      },
      retries: 1,
      method: 'PATCH',
      body,
    });
    if (!isEmpty(response)) {
      response.valuation = roundValuation(response);
    }
  } catch (e) {
    response = {};
    errorStatus = e.status;
  }

  let conditionPrice = 0;
  if (conditionPricing) {
    conditionPrice = conditionPricing[condition.toLowerCase()] || conditionPricing[CONDITIONS.CLEAN.toLowerCase()] || 0;
  }

  const {
    offerCreativeId = PARTNER_OFFER_TRACKING_CREATIVE_ID,
    noOfferCreativeId = PARTNER_OFFER_NO_INSTANT_OFFER_TRACKING_CREATIVE_ID,
  } = creativeIds || {};

  if (!isEmpty(response)) {
    const { declineReason, metaData } = response;
    const cpsMetadata = JSON.parse(get(metaData, 'cpsMetadata', '{}'));
    if (get(cpsMetadata, 'estimateCategory.length') > 0) {
      const estimateByCategory = keyBy(cpsMetadata.estimateCategory, 'category');
      const { minEstimate, maxEstimate } = getEstimateValues(estimateByCategory);
      fireEstimatedOfferRange(minEstimate, maxEstimate, TrackingConstant.SUBACTION_OFFER_ESTIMATE_PATCH);
    }
    if (declineReason) {
      const { valuation } = response;
      EventToolbox.fireTrackAction(DECLINE_REASON_VALUE_TRACKING(noOfferCreativeId, declineReason));
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
        event_data: {
          action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
          subaction_name: TrackingConstant.SUBACTION_DECLINE_REASON,
          creative_id: noOfferCreativeId,
          value: `${vinInUpperCase}_epo_offer_eligibility_check_${valuation || 0}_${declineReason}`,
        },
      });
    }
    if (!hasTmvError) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_RECEIVE_EPO,
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
          subaction_name: TrackingConstant.SUBACTION_TMV_APPRAISAL,
          creative_id: offerCreativeId,
          value: conditionPrice,
        },
      });
    }
  }

  const epoOfferTracking = EPO_OFFER_TRACKING({
    creativeId: offerCreativeId,
    valuation: response?.valuation,
    errorValue: errorStatus && `${errorStatus}_ERROR`,
  });
  const valuationTrackingValue = epoOfferTracking?.event_data?.value;
  EventToolbox.fireTrackAction(epoOfferTracking);

  EventToolbox.fireTrackAction(OFFER_CODE_TRACKING(offerCreativeId, response?.code));

  const { sourceId, vin: responseVin, valuation } = response;
  if (sourceId) {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_END,
      event_data: {
        action_name: TrackingConstant.ACTION_RECEIVE_EPO,
        subaction_name: TrackingConstant.SUBACTION_SOURCE_ID,
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        creative_id: offerCreativeId,
        value: sourceId,
      },
    });
  }

  if (valuation > 0 && responseVin) {
    EventToolbox.fireTrackAction(EPO_OFFER_VIN_TRACKING(offerCreativeId, responseVin));
  }

  if (
    conditionPrice &&
    (valuationTrackingValue === TrackingConstant.ZERO_VALUATION || `${valuationTrackingValue}`.includes('_ERROR'))
  ) {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_name: TrackingConstant.ACTION_RECEIVE_EPO,
        subaction_name: TrackingConstant.SUBACTION_VIN_ERROR,
        creative_id: offerCreativeId,
        value: `${vin}_${TrackingConstant.SUBACTION_EPO_OFFER}_${valuationTrackingValue}`,
      },
    });
  }
  return response;
};

/**
 * Obtains an offer from CarMax API and stores it into MODS (Multi-Offer Data Store).
 * The MODS call happens only if the CarMax API responds successfully (i.e. returns an offer).
 *
 * @param {Object} carmaxPayload object that contains data for the call to CarMax API.
 * @param {Object} carmaxCreativeIds creative ID overrides to be used for CarMax offer tracking (optional).
 * @param {Object} carmaxOptions options to configure the CarMax API call (optional).
 * @param {Function} buildModsPayload function to build the payload for creating the MODS record, accepts the CarMax API response.
 * @param {Object} modsOptions options to configure the Create MODS record call (optional).
 * @returns {Promise<{carmaxResponse: {}, modsResponse: {}}>} responses from CarMax and MODS calls.
 */
export const obtainCarmaxOfferAndStoreIntoMods = async ({
  carmaxProps: { payload: carmaxPayload, creativeIds: carmaxCreativeIds, options: carmaxOptions },
  modsProps: { buildPayload: buildModsPayload, options: modsOptions },
}) => {
  const carmaxResponse = await submitPartnerOfferAppraisal(carmaxPayload, carmaxCreativeIds, carmaxOptions);

  if (isEmpty(carmaxResponse)) {
    return { carmaxResponse, modsResponse: {} };
  }

  // Remove the CarMax print URL in order to trigger the generation of the Edmunds print URL.
  carmaxResponse.redemptionCertificateUri = '';

  const modsPayload = buildModsPayload(carmaxResponse);
  const modsResponse = await createModsRecord(modsPayload, modsOptions);

  if (!modsResponse.isError) {
    // Store the generated Edmunds print URL.
    carmaxResponse.redemptionCertificateUri = modsResponse.offerPrintUrl;
  }

  return { carmaxResponse, modsResponse };
};

const getEstimatedOfferDeclineReason = responseText => {
  try {
    const json = JSON.parse(responseText);
    if (Array.isArray(json)) {
      return json[0];
    }
    if (typeof json === 'string') {
      return json;
    }
    return json.reason || responseText;
  } catch (e) {
    return responseText;
  }
};

/**
 * Get estimated offers.
 * @param {Object} data
 * @param {Object} options - OPTIONAL, lets the caller configure the API call.
 * @returns {Object}
 */
export const getPartnerEstimatedOffers = async ({ sku, vin, mileage, zipCode }, options = {}) => {
  if (!sku) {
    // sku is required
    return {};
  }
  let styleSpecsResponse = {};
  let estimatorResponse = {};
  try {
    styleSpecsResponse = await EdmundsPartnerOfferAPI.fetchJson(`/specifications/style/${sku}`, {
      headers: {
        ...options.headers,
        'content-type': 'application/json',
        ...VENOM_X_PRODUCT_ID_HEADER,
      },
      method: 'GET',
    });
  } catch (e) {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
        subaction_name: TrackingConstant.SUBACTION_EPO_SKU_SPEC_LOOKUP,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        creative_id: PARTNER_OFFER_TRACKING_CREATIVE_ID,
        value: `${e.status}_ERROR`,
      },
    });
    return {};
  }

  const {
    year,
    make: { code: makeCode },
    model: { code: modelCode },
    body: { code: bodyCode },
    trim: { code: trimCode },
  } = styleSpecsResponse;

  const skuSpecTrackingValueNumberOfValues = 5;
  const skuSpecTrackingArr = [year, makeCode, modelCode, bodyCode, trimCode];
  const skuSpecTrackingArrFiltered = skuSpecTrackingArr.filter(val => !!val);
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
      subaction_name: TrackingConstant.SUBACTION_EPO_SKU_SPEC_LOOKUP,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      creative_id: PARTNER_OFFER_TRACKING_CREATIVE_ID,
      value:
        skuSpecTrackingArrFiltered.length === skuSpecTrackingValueNumberOfValues
          ? 'all_sku_codes_received'
          : `missing_${skuSpecTrackingValueNumberOfValues - skuSpecTrackingArrFiltered.length}_sku_codes`,
    },
  });

  const isMissingRequiredValues = [...skuSpecTrackingArr, vin, mileage, zipCode].some(val => !val);
  if (isMissingRequiredValues) return {};

  try {
    const body = JSON.stringify({
      modelYear: parseInt(year, 10), // Must be an integer/number
      makeCode,
      modelCode,
      trimCode,
      bodyCode,
      vin,
      mileage: parseInt(mileage, 10), // Must be an integer/number
      zipCode,
    });
    const response = await EdmundsPartnerOfferAPI.fetchJson(`/estimator`, {
      headers: {
        ...options.headers,
        'content-type': 'application/json',
        ...VENOM_X_PRODUCT_ID_HEADER,
      },
      method: 'POST',
      body,
      includeResponseInError: true,
    });
    if (isEmpty(response) || isEmpty(response.estimateCategory)) {
      return {};
    }
    const { estimate, estimateCategory } = response;
    const estimateByCategory = keyBy(estimateCategory, 'category');
    estimatorResponse = {
      estimate: estimate || 0,
      estimateByCategory,
    };
    const { minEstimate, maxEstimate } = getEstimateValues(estimateByCategory);
    fireEstimatedOfferRange(minEstimate, maxEstimate, TrackingConstant.SUBACTION_OFFER_ESTIMATE);
  } catch (e) {
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
        subaction_name: TrackingConstant.SUBACTION_OFFER_ESTIMATE,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        creative_id: PARTNER_OFFER_TRACKING_CREATIVE_ID,
        value: `${e.status}_ERROR`,
      },
    });
    if (e.status === HTTP_BAD_REQUEST) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
        event_data: {
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
          subaction_name: TrackingConstant.SUBACTION_ESTIMATE_DECLINE_REASON,
          action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
          creative_id: PARTNER_OFFER_ESTIMATED_OFFER_CREATIVE_ID,
          value: getEstimatedOfferDeclineReason(e.responseText),
        },
      });
    }
    return {};
  }

  return estimatorResponse;
};

export const getNewOfferQuotesId = async vin => {
  if (!vin) {
    throw new Error("Invalid VIN can't be used to determine partner offer eligibility");
  }
  let response;

  try {
    response = await EdmundsPartnerOfferAPI.fetchJson('/v2/quotes', {
      headers: {
        'content-type': 'application/json',
        ...VENOM_X_PRODUCT_ID_HEADER,
      },
      method: 'POST',
      body: JSON.stringify({ vin: vin.toUpperCase() }),
    });
  } catch (e) {
    return null;
  }

  const { id } = response;

  return id;
};

export const getPartnerOfferEligibilityByVin = async (vin, context, trackingCreativeId) => {
  const isEmo2633Chal = await context.resolveValue('isEmo2633Chal');
  if (isEmo2633Chal) {
    return {};
  }

  if (!vin) {
    throw new Error("Invalid VIN can't be used to determine partner offer eligibility");
  }

  let response;
  const fallbackResponse = {
    isEligible: false,
  };

  try {
    response = await withMetrics(EdmundsPartnerOfferAPI, context).fetchJson('/v2/quotes', {
      headers: {
        'content-type': 'application/json',
        ...VENOM_X_PRODUCT_ID_HEADER,
      },
      method: 'POST',
      body: JSON.stringify({ vin: vin.toUpperCase() }),
    });
    if (isEmpty(response)) {
      response = fallbackResponse;
    }
  } catch (e) {
    response = fallbackResponse;
  }

  const { id: quoteId, isEligible, reason } = response;
  const pixelEventData = getVinEligibilityCheckEventData();

  pixelEventData.creative_id = trackingCreativeId;

  if (!isEligible) {
    pixelEventData.value = reason || TRACKING_VALUES.api_failure;

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        ...pixelEventData,
        action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
        subaction_name: TrackingConstant.SUBACTION_VIN_ERROR,
        action_cause: pixelEventData.action_cause,
        value: `${vin}_${pixelEventData.subaction_name}_${pixelEventData.value}`,
      },
    });
  } else {
    pixelEventData.value = TRACKING_VALUES.vin_eligible;
  }

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: pixelEventData,
  });

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_END,
    event_data: {
      action_name: TrackingConstant.ACTION_RECEIVE_EPO,
      subaction_name: TrackingConstant.SUBACTION_QUOTE_ID_RETURNED,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      /* Originally we always have this creative_id as PARTNER_OFFER_TRACKING_CREATIVE_ID regardless of the "trackingCreativeId"
      passed through, but since EVAL-4048 we also need to set APPRAISAL_OFFER_RENEWAL_CREATIVE_ID. So it can only be one of two
      creative_id, PARTNER_OFFER_TRACKING_CREATIVE_ID or APPRAISAL_OFFER_RENEWAL_CREATIVE_ID */
      creative_id:
        trackingCreativeId === APPRAISAL_OFFER_RENEWAL_CREATIVE_ID
          ? APPRAISAL_OFFER_RENEWAL_CREATIVE_ID
          : PARTNER_OFFER_TRACKING_CREATIVE_ID,
      value: quoteId,
    },
  });

  return response;
};

export const transformStore = ({
  id,
  name,
  address_line_1: addressLine1,
  address_line_2: addressLine2,
  city,
  state,
  zip_code: zipCode,
  longitude,
  latitude,
  message,
  store_url: storeUrl,
  distance_in_miles: distance,
  phone,
}) => ({
  id,
  name,
  addressLine1,
  addressLine2,
  city,
  state,
  zipCode,
  longitude,
  latitude,
  message,
  storeUrl,
  distance,
  phone,
});

const buildEligibilityFallbackResponse = response => ({
  isEligible: false,
  reason: (response && response.reason) || null,
  nearestStores: [],
  id: uuidv4(),
});

export const getPartnerOfferEligibilityByYmmZip = async (
  { make, model, year, zipCode },
  context,
  trackingCreativeId
) => {
  const isEmo2633Chal = await context.resolveValue('isEmo2633Chal');
  if (isEmo2633Chal) {
    return {};
  }

  let response;

  try {
    response = await withMetrics(EdmundsPartnerOfferAPI, context).fetchJson(
      `/eligibility?ZipCode=${zipCode}&Make=${make}&Model=${model}&Year=${year}`,
      { headers: VENOM_X_PRODUCT_ID_HEADER }
    );

    if (isEmpty(response)) {
      response = buildEligibilityFallbackResponse(response);
    } else {
      const { store, isEligible, reason, nearestStores } = response;
      response = {
        store: transformStore(store),
        isEligible: !!isEligible,
        reason,
        nearestStores: nearestStores.map(transformStore),
        id: uuidv4(),
      };
    }
  } catch (e) {
    response = buildEligibilityFallbackResponse(response);
  }

  let trackingValue;
  const message = response.store ? response.store.message : undefined;
  if (response.isEligible) {
    trackingValue = getMmyZipEligibilityMessageTrackingValue(message);
  } else {
    trackingValue = response.reason ? response.reason : TRACKING_VALUES.mmy_zip_ineligible;
  }

  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      ...getYmmzEligibilityCheckEventData(trackingCreativeId),
      value: trackingValue,
    },
  });

  return response;
};
export const getLicensePlateErrorState = state => (state.partnerOffer ? state.partnerOffer.licensePlateError : null);

export const getVinsByAddress = async address => {
  let response;
  try {
    response = await EdmundsPartnerOfferAPI.fetchJson('/vins/search-by-address', {
      headers: { ...VENOM_X_PRODUCT_ID_HEADER, 'content-type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(address),
    });
    const { vehicles = [], message = 'No VINs returned for address' } = response;
    if (!vehicles.length) {
      throw new Error(message);
    }
    response = {
      vehicles,
    };
  } catch (e) {
    response = {
      vehicles: [],
    };
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
      event_data: {
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
        action_name: TrackingConstant.ACTION_RECEIVE_EPO,
        creative_id: PARTNER_OFFER_ADDRESS_LOOKUP,
        value: `${e.status}_ERROR`,
      },
    });
  }

  return response;
};

export const getTmvHistory = async ({ styleId, zip, mileage, monthlyMileage, optionIds, condition, interval }) => {
  let response;

  try {
    response = await EdmundsAPI.fetchJson(
      `/usedtmv/v4/historicaltmvs?${objectToQueryString(
        pickBy(
          {
            styleId,
            zip,
            mileage,
            monthlyMileage,
            optionIds,
            condition,
            interval: interval || 'MONTH',
            intervalCount: 12,
          },
          identity
        )
      )}`
    );
    const { historicalTmv } = response;
    const tmvValues = get(historicalTmv, 'pricesWithAdjustments.adjustedPrices', []);
    const usedTradeInPrice = get(tmvValues, '[0].price.usedTradeIn');
    if (tmvValues.length <= 1 && !usedTradeInPrice) {
      response = { noTmvData: true };
    } else {
      response = historicalTmv;
    }
  } catch (e) {
    response = { noTmvData: true };
  }

  return response;
};

export const PARTNER_OFFER_PARAMETERS_PATH = {
  VIN: 'offerParameters.vin',
  QUOTE_ID: 'offerParameters.quoteId',
  EQUIPMENT_OPTIONS_TRANSLATION: 'offerParameters.equipmentOptions',
  MODS_EQUIPMENT_OPTIONS_TRANSLATION: 'offerParameters.modsEquipmentOptions',
  CHROME_STYLES_TRANSLATION: 'offerParameters.chromeStyles',
};

export function buildLicensePlateStateCodeForVinPath({ licensePlate, stateCode }) {
  return licensePlate && stateCode ? `vin.licensePlate["${licensePlate}"].stateCode["${stateCode}"]` : '';
}

export function buildChromeStyleAndOptionsTranslationPath({
  styleId,
  chromeStyleIds,
  engineSize,
  transmissionType,
  vin,
}) {
  if (styleId) {
    const chromeId = get(chromeStyleIds, '[0]');
    const engine = engineSize || undefined;
    const transmission = transmissionType || undefined;
    const vinToPath = vin || undefined;
    return `styleId["${styleId}"].chromeStyleIds["${chromeId}"].engineSize["${engine}"].transmissionType["${transmission}"].vin["${vinToPath}"].skuAndOptions`;
  }
  return '';
}

export function buildPartnerOfferYmmzEligibilityPath({ make, model, year, zipCode }) {
  return make && model && year && zipCode
    ? `zipCode["${zipCode}"].make["${make}"].model["${model}"].year["${year}"].ymmzEligibility`
    : '';
}

export function buildOfferRenewalYmmzEligibilityPath({ vin, modsId }) {
  return vin ? `vin["${vin}"].modsId["${modsId || 'null'}"].ymmzEligibility` : '';
}

export function buildPartnerOfferSquishStylesValidationPath({ vin, make, model, year }) {
  return vin && make && model && year
    ? `vin["${vin}"].make["${make}"].model["${model}"].year["${year}"].squishStyles`
    : '';
}

export function buildPartnerOfferVinEligibilityPath({ make, model, year, vin }) {
  return make && model && year && vin
    ? `vin["${vin}"].make["${make}"].model["${model}"].year["${year}"].eligibility`
    : '';
}

export const partnerOfferVehicleInfoByVinPath = {
  build: ({ vin }) => (vin ? `vin["${vin}"].vehicle.info` : ''),
  segment: 'vin["{vin}"].vehicle.info',
};

export const partnerOfferVehicleInfoByModsIdPath = {
  build: ({ modsId }) => (modsId ? `modsId["${modsId}"].vehicle.info` : ''),
  segment: 'modsId["{modsId}"].vehicle.info',
};

export const buildPartnerOfferVehicleInfoPath = ({ vin, modsId }) => {
  if (modsId) {
    return partnerOfferVehicleInfoByModsIdPath.build({ modsId });
  }
  if (vin) {
    return partnerOfferVehicleInfoByVinPath.build({ vin });
  }
  return '';
};

/**
 * Should only be used to retrieve partner offer vin eligibility in cases where the model is not available,
 * typically during the offer renewal process.
 * @param vin
 * @returns {string|string}
 */
export function buildPartnerOfferVinEligibilityByVinPath({ vin }) {
  return vin ? `vin["${vin}"].eligibility` : '';
}

export function buildPartnerOffersConditionsQuestionsPath(location) {
  const isE2E = getQuery(location).e2e;
  return `conditionsQuestions${isE2E ? '.e2e' : ''}`;
}
/**
 * CarMax questions transformed for usage in VAC Step 4.
 */
export const transformedConditionsQuestionsPath = {
  build: () => 'transformedConditionsQuestions',
  segment: 'transformedConditionsQuestions',
};

/**
 *
 * @param {String} offerCode - Required to create new call (and cache) based off of every offer
 * @param {Object} store - CarMax store information to obtain storeId
 * @param {String} startTime - Date string (Local time)
 * @param {String} endTime - Date string (Local time)
 * @returns {String} luckdragon path to get available appointment slots.
 */
export function buildPartnerOffersAppointmentsSlotsPath({ offerCode, store, startTime, endTime }) {
  const storeId = get(store, 'id');
  return offerCode && storeId && startTime && endTime
    ? `offerCode["${offerCode}"].appointments.storeId["${storeId}"].startTime["${startTime}"].endTime["${endTime}"].slots`
    : '';
}

const fireOptionsTranslationTracking = (subaction, value) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_RECEIVE_EPO,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      subaction_name: subaction,
      creative_id: APPRAISAL_PAGE_CREATIVE_ID,
      value,
    },
  });
};

const fireChromeStylesSkuTranslationTracking = (subaction, value) => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_RECEIVE_EPO,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      subaction_name: subaction,
      creative_id: APPRAISAL_PAGE_CREATIVE_ID,
      value,
    },
  });
};

const fireGetAppointmentSlotsTracking = value => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_RECEIVE_EPO,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      subaction_name: TrackingConstant.SUBACTION_EPO_GET_APPOINTMENT_SLOTS,
      creative_id: PARTNER_OFFER_APPOINTMENT_DRAWER_CREATIVE_ID,
      value,
    },
  });
};

const fireReceiveAppointmentSlotsTracking = value => {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
    event_data: {
      action_name: TrackingConstant.ACTION_RECEIVE_EPO,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_BUTTON_CLICK,
      subaction_name: TrackingConstant.SUBACTION_EPO_RECEIVE_APPOINTMENT_SLOTS,
      creative_id: PARTNER_OFFER_APPOINTMENT_DRAWER_CREATIVE_ID,
      value,
    },
  });
};

export const getEquipmentOptions = ({ sku, optionsNames, optionsOemCodes }, context) => {
  const fallbackResponse = {
    standardOptionCodes: [],
    availableOptionCodes: [],
  };
  return withMetrics(EdmundsPartnerOfferAPI, context)
    .fetchJson(`/v2/translation/equipment-options`, {
      headers: {
        'content-type': 'application/json',
        ...VENOM_X_PRODUCT_ID_HEADER,
      },
      retries: 2, // Explicitly requested in EVAL-3779
      method: 'POST',
      body: JSON.stringify({
        sku,
        partnerEquipmentDescriptions: optionsNames,
        manufacturerOptionCodes: optionsOemCodes,
      }),
    })
    .then(response => {
      const { standardOptionCodes, availableOptionCodes } = response;
      fireOptionsTranslationTracking(TrackingConstant.SUBACTION_EPO_TRANSLATION_OPTIONS, 'Success');
      fireOptionsTranslationTracking(
        TrackingConstant.SUBACTION_EPO_TRANSLATION_STANDARD_OPTION_COUNT,
        standardOptionCodes && standardOptionCodes.length
      );
      fireOptionsTranslationTracking(
        TrackingConstant.SUBACTION_EPO_TRANSLATION_AVAILABLE_OPTION_COUNT,
        availableOptionCodes && availableOptionCodes.length
      );

      return isEmpty(response)
        ? fallbackResponse
        : {
            standardOptionCodes,
            availableOptionCodes,
          };
    })
    .catch(e => {
      fireOptionsTranslationTracking(TrackingConstant.SUBACTION_EPO_TRANSLATION_OPTIONS, `${e.status}_ERROR`);
      return fallbackResponse;
    });
};

/**
 * Clears vinEligibility path (by setting it to "undefined") so it can make a new call to obtain a quoteId
 * @param vin
 * @param make
 * @param model
 * @param year
 * @returns {Promise<void>}
 */
export const clearVinEligibilityCache = async ({ context, vin, make, model, year }) => {
  await context.updateValue(buildPartnerOfferVinEligibilityPath({ vin, make, model, year }), undefined);
};

export const updateAndGetVinEligibilityCache = async ({ context, vin, make, model, year, dataModel }) =>
  context.resolveValue(buildPartnerOfferVinEligibilityPath({ vin, make, model, year }), dataModel);

export const PartnerOfferModel = createModelSegment('partnerOffer', [
  /**
   * Sample of the partner API call:
   * /api/partner-offers/eligibility?Make=Ford&Model=Focus&Year=2016&ZipCode=23220
   */
  {
    path: 'zipCode["{zipCode}"].make["{make}"].model["{model}"].year["{year}"].ymmzEligibility',
    async resolve({ make, model, year, zipCode }, context) {
      const ymmzEligibilityCreativeIds = await context.resolveValue('ymmzEligibilityCreativeIds', PartnerOfferModel);
      const creativeId = get(ymmzEligibilityCreativeIds, 'value', APPRAISAL_PAGE_CREATIVE_ID);

      const response = await getPartnerOfferEligibilityByYmmZip({ make, model, year, zipCode }, context, creativeId);
      return response;
    },
  },
  {
    path: 'vin["{vin}"].modsId["{modsId}"].ymmzEligibility',
    async resolve({ vin, modsId }, context) {
      const modsData = await context.resolveValue(offerRenewalModsDataPath.build({ vin, modsId }), MultiOfferModel);

      if (isEmpty(modsData)) {
        return null;
      }

      try {
        const { vehicle, seller } = modsData;
        return context.resolveValue(
          buildPartnerOfferYmmzEligibilityPath({
            make: vehicle.makeSlug,
            model: vehicle.modelSlug,
            year: vehicle.year,
            zipCode: seller.zipCode,
          }),
          PartnerOfferModel
        );
      } catch {
        return null;
      }
    },
  },
  /**
   * Chrome Style SKU and Equipment Options Translation ARE chained together
   * See EVAL-3817 and EVAL-3818 for full details.
   */
  {
    path:
      'styleId["{styleId}"].chromeStyleIds["{chromeStyleIds}"].engineSize["{engineSize}"].transmissionType["{transmissionType}"].vin["{vin}"].skuAndOptions',
    async resolve(match, context) {
      const isEmo2633Chal = await context.resolveValue('isEmo2633Chal');

      if (isEmo2633Chal) return {};

      const { styleId, vin } = match;
      // square brackets should be encoded.
      const encodedEdmundsStyleIdMetadataKey = encodeURIComponent('Metadata[EdmundsStyleId]');
      const encodedEdmundsVinMetadataKey = encodeURIComponent('Metadata[Vin]');

      const apiQueryParamArr = [];
      const queryParams = [
        { valueKey: 'chromeStyleIds', parameterKey: 'ChromeStyleId' },
        { valueKey: 'engineSize', parameterKey: 'EngineSize' },
        { valueKey: 'transmissionType', parameterKey: 'TransmissionType' },
      ];
      const isVinDefined = vin !== 'undefined';

      queryParams.forEach(param => {
        if (`${match[param.valueKey]}` !== 'undefined')
          apiQueryParamArr.push(`${param.parameterKey}=${match[param.valueKey]}`);
      });

      apiQueryParamArr.push(`${encodedEdmundsStyleIdMetadataKey}=${styleId}`);

      if (isVinDefined) {
        apiQueryParamArr.push(`${encodedEdmundsVinMetadataKey}=${vin}`);
      }

      const url = `/v2/translation/chrome-style?${apiQueryParamArr.join('&')}`;

      let chromeStyleSku = '';

      try {
        const content = await withMetrics(EdmundsPartnerOfferAPI, context).fetch(url, {
          headers: {
            'content-type': 'application/json',
            ...VENOM_X_PRODUCT_ID_HEADER,
          },
          method: 'GET',
        });
        if (content.status === HTTP_NO_CONTENT) {
          /**
           * a 204 is not technically an error, but analytics efforts will be simplified if the new string
           * aligns with similar error tracking (e.g., "404_ERROR"), so we will track this response in EDW
           * using the string "204_ERROR"
           */
          fireChromeStylesSkuTranslationTracking(
            TrackingConstant.SUBACTION_EPO_TRANSLATION_SKU,
            `${HTTP_NO_CONTENT}_ERROR`
          );
        } else {
          const { sku } = await content.json();
          chromeStyleSku = sku;
          fireChromeStylesSkuTranslationTracking(TrackingConstant.SUBACTION_EPO_TRANSLATION_SKU, sku);
        }
      } catch ({ status }) {
        fireChromeStylesSkuTranslationTracking(TrackingConstant.SUBACTION_EPO_TRANSLATION_SKU, `${status}_ERROR`);
      }
      const returnObj = {
        sku: chromeStyleSku,
        standardOptions: [],
        availableOptions: [],
      };

      if (chromeStyleSku) {
        // Equipment Options translation
        const { optionsNames = [], optionsOemCodes = [] } = await context.resolveValue(
          PARTNER_OFFER_PARAMETERS_PATH.EQUIPMENT_OPTIONS_TRANSLATION,
          PartnerOfferModel
        );

        const { standardOptionCodes, availableOptionCodes } = await getEquipmentOptions(
          {
            sku: chromeStyleSku,
            optionsNames,
            optionsOemCodes,
          },
          context
        );

        returnObj.standardOptions = standardOptionCodes;
        returnObj.availableOptions = availableOptionCodes;
      }

      return returnObj;
    },
  },
  {
    path: 'chromeStyleSku["{chromeStyleSku}"].equipmentOptions',
    async resolve(match, context) {
      const { chromeStyleSku } = match;
      const returnObj = {
        sku: chromeStyleSku,
        standardOptions: [],
        availableOptions: [],
      };

      const { optionsNames = [], optionsOemCodes = [] } = await context.resolveValue(
        PARTNER_OFFER_PARAMETERS_PATH.MODS_EQUIPMENT_OPTIONS_TRANSLATION,
        PartnerOfferModel
      );
      const { standardOptionCodes, availableOptionCodes } = await getEquipmentOptions(
        {
          sku: chromeStyleSku,
          optionsNames,
          optionsOemCodes,
        },
        context
      );

      return {
        ...returnObj,
        standardOptions: standardOptionCodes,
        availableOptions: availableOptionCodes,
      };
    },
  },
  {
    path: 'vin["{vin}"].make["{make}"].model["{model}"].year["{year}"].squishStyles',
    async resolve({ vin, make, model, year }, context) {
      const squishStyles = await context.resolveValue(
        buildStylesBasicPathFromSquishVin({ squishVin: getSquishVIN(vin) }),
        VehicleVinModel
      );
      const isSquishVinValid = squishStyles.length
        ? squishStyles.some(
            ({ makeName = '', modelName = '', year: squishStyleYear }) =>
              make &&
              make.toLowerCase() === makeName.toLowerCase() &&
              model &&
              model.toLowerCase() === modelName.toLowerCase() &&
              year === `${squishStyleYear}`
          )
        : true;

      return {
        isSquishVinValid,
        squishStyles,
        isCheckDigitValid: true, // We assume this is true because partner offer eligibility will determine whether it is false.
      };
    },
  },
  {
    path: partnerOfferVehicleInfoByVinPath.segment,
    async resolve({ vin }, context) {
      let dataByVin;

      try {
        dataByVin = await context.resolveValue(searchByVinPath.build(vin), MultiOfferModel);
      } catch {
        dataByVin = {};
      }

      if (!isEmpty(dataByVin)) {
        const { vehicle, kmxConditionQuestions, kmx, carwiser } = dataByVin;

        return getVehicleInfoFromModsData({
          vehicle,
          kmxConditionQuestions,
          carwiserConditionQuestions: get(carwiser, 'conditionQuestions', []),
          offerFinalizedAt: kmx.offersFinalizedAt,
          seller: dataByVin.seller,
        });
      }

      return {
        source: 'match_not_found',
      };
    },
  },
  {
    path: partnerOfferVehicleInfoByModsIdPath.segment,
    async resolve({ modsId }, context) {
      let modsRecord;

      try {
        modsRecord = await context.resolveValue(modsRecordPath.build(modsId), MultiOfferModel);
      } catch {
        modsRecord = {};
      }

      if (!isEmpty(modsRecord)) {
        const { vehicle, conditionDetails } = modsRecord;

        const kmxConditionQuestions = JSON.parse(get(conditionDetails, 'kmx.rawData', '[]'));
        const carwiserConditionQuestions = JSON.parse(get(conditionDetails, 'carwiser.rawData', '[]'));
        const offerFinalizedAt = get(modsRecord, 'partners.kmx.offersFinalizedAt');

        return getVehicleInfoFromModsData({
          vehicle,
          kmxConditionQuestions,
          carwiserConditionQuestions,
          offerFinalizedAt,
          seller: modsRecord.seller,
        });
      }

      // Fallback for when MODS record is empty
      const vin = await context.resolveValue('estimatedAppraisalVin', VehicleModel);

      if (vin) {
        return context.resolveValue(partnerOfferVehicleInfoByVinPath.build({ vin }), PartnerOfferModel);
      }

      return {
        source: 'match_not_found',
      };
    },
  },
  /**
   * Sample of the partner API call:
   * (POST) /api/partner-offers/v2/quotes
   */
  {
    path: 'vin["{vin}"].make["{make}"].model["{model}"].year["{year}"].eligibility',
    async resolve({ vin, make, model, year }, context) {
      const { isSquishVinValid, squishStyles } = await context.resolveValue(
        buildPartnerOfferSquishStylesValidationPath({ vin, make, model, year }),
        PartnerOfferModel
      );

      let isEligibleVin = false;
      let isCheckDigitValid = true; // We want to assume this is true.
      let lastQuoteConditionQuestionsResponses = [];
      let lastQuoteValues = {};
      let quoteId = null;
      let createdDateUtc;
      const eligibilityCreativeIds = await context.resolveValue('eligibilityCreativeIds', PartnerOfferModel);
      const creativeId = get(eligibilityCreativeIds, `${vin}`, LICENSE_PLATE_AND_VIN_CREATIVE_ID);

      if (isSquishVinValid) {
        const { isEligible, reason, lastQuote, id } = await getPartnerOfferEligibilityByVin(vin, context, creativeId);
        isEligibleVin = isEligible;
        isCheckDigitValid = reason !== VIN_ELIGIBILITY_INVALID_VIN;
        lastQuoteConditionQuestionsResponses =
          (isEligible &&
            get(lastQuote, 'vin', '').toUpperCase() === vin.toUpperCase() &&
            lastQuote.conditionQuestions) ||
          [];
        if (lastQuote) {
          lastQuoteValues = {
            mileage: calculatePrepopulatedMileage(lastQuote),
            condition: get(lastQuote, 'metaData.edmunds-Condition-Input'),
            transmission: lastQuote.transmission,
            valuation: get(lastQuote, 'valuation'),
          };
          createdDateUtc = lastQuote.createdDateUtc;
        }
        quoteId = id;
      }
      await context.updateValue(PARTNER_OFFER_PARAMETERS_PATH.QUOTE_ID, quoteId);

      return {
        isEligibleVin,
        isCheckDigitValid,
        isSquishVinValid,
        squishStyles,
        lastQuoteConditionQuestionsResponses,
        lastQuoteValues,
        createdDateUtc,
      };
    },
  },
  {
    path: 'partnerOfferPrerequisites',
    async resolve(_, context) {
      // The following data should exist before, but in an event where it doesn't exist (or has a race condition) we will try to make the call
      const [{ zipCode, stateCode, dma }, { styleId }] = await Promise.all([
        context.resolveValue('location', VisitorModel),
        context.resolveValue('allTmvParams', VehicleModel),
      ]);
      // In the event if "allTmvParams" returns nothing, we should let the following error bubble up because it should be available!

      const [style, { tmvconditions: tmvConditions, hasError: hasTmvError }, vehicleFeatures] = await Promise.all([
        context.resolveValue(`styles.${styleId}`, VehicleModel),
        context.resolveValue(buildUsedTmvPricingWithOptionsPath(styleId), VehicleModel),
        context.resolveValue(buildOptionWithOemCodeStylePath(styleId), VehicleModel),
      ]);

      const { makeSlug, makeName, modelSlug, modelName, year } = style;
      const [vin, chromeStylesParameter, lastQuoteId, multiOfferPrequalification] = await Promise.all([
        context.resolveValue(PARTNER_OFFER_PARAMETERS_PATH.VIN, PartnerOfferModel),
        context.resolveValue(PARTNER_OFFER_PARAMETERS_PATH.CHROME_STYLES_TRANSLATION, PartnerOfferModel),
        context.resolveValue(PARTNER_OFFER_PARAMETERS_PATH.QUOTE_ID, PartnerOfferModel),
        stateCode && dma && year
          ? context.resolveValue(prequalificationPath.build({ stateCode, dma, year }), MultiOfferModel)
          : Promise.resolve({}),
      ]);
      const quoteIdExists = !!lastQuoteId;

      const vinEligibilityProps = { context, vin, make: makeName, model: modelName, year };
      if (!quoteIdExists) {
        clearVinEligibilityCache(vinEligibilityProps);
      }

      const { isEligible: isYmmzEligible, reason } = await context.resolveValue(
        buildPartnerOfferYmmzEligibilityPath(
          {
            zipCode,
            make: makeSlug,
            model: modelSlug,
            year,
          },
          PartnerOfferModel
        )
      );
      let skuAndOptions = {};
      let vinEligibility = {};

      const isZipCodeEligble = reason !== INELIGIBILITY_REASONS.ZIP_INELIGIBLE;

      if (isZipCodeEligble) {
        skuAndOptions = await context.resolveValue(
          buildChromeStyleAndOptionsTranslationPath({
            ...chromeStylesParameter,
            vin,
            styleId,
          }),
          PartnerOfferModel
        );
        if (isYmmzEligible) {
          vinEligibility = await updateAndGetVinEligibilityCache({
            ...vinEligibilityProps,
            dataModel: PartnerOfferModel,
          });
        }
      }

      const quoteId =
        lastQuoteId || (await context.resolveValue(PARTNER_OFFER_PARAMETERS_PATH.QUOTE_ID, PartnerOfferModel));

      return {
        tmvConditions,
        hasTmvError,
        vehicleFeatures,
        skuAndOptions,
        vinEligibility,
        quoteId,
        multiOfferPrequalification,
        responseId: uuidv4(),
      };
    },
  },
  {
    path: 'offerParameters', // See PARTNER_OFFER_PARAMETERS_PATH const for available paths
  },
  {
    path: PARTNER_OFFER_PARAMETERS_PATH.QUOTE_ID,
  },
  {
    path:
      'offerCode["{offerCode}"].appointments.storeId["{storeId}"].startTime["{startTime}"].endTime["{endTime}"].slots',
    async resolve({ storeId, startTime, endTime }, context) {
      let response;
      const fallbackResponse = [];

      try {
        fireGetAppointmentSlotsTracking(`store_${storeId}`);
        response = await withMetrics(EdmundsPartnerOfferAPI, context).fetchJson(
          `/v2/appointments/slots?storeId=${storeId}&startTime=${startTime}&endTime=${endTime}`,
          { headers: VENOM_X_PRODUCT_ID_HEADER }
        );
        if (response.length) {
          fireReceiveAppointmentSlotsTracking('available_appointments_found');
        }
        if (isEmpty(response)) {
          response = fallbackResponse;
        }
      } catch (e) {
        response = fallbackResponse;
        fireReceiveAppointmentSlotsTracking(`${e.status}_ERROR`);
      }

      return response;
    },
  },
  {
    /**
     * The endpoint consist of both Experian and IHS calls that is done in the API level.
     *
     * Sample of the partner API call:
     * /api/partner-offers/vins/search-by-plate
     */
    path: 'vin.licensePlate["{licensePlate}"].stateCode["{stateCode}"]',
    async resolve({ licensePlate, stateCode }, context) {
      let response;
      const createdDateUtc = getSyncDate();
      const quotebackId = uuidv5(`${stateCode}|${createdDateUtc}`, EPO_UUIDV5_NAMESPACE);
      try {
        response = await withMetrics(EdmundsPartnerOfferAPI, context).fetchJson('/vins/search-by-plate', {
          headers: { ...VENOM_X_PRODUCT_ID_HEADER, 'content-type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({
            plateNumber: licensePlate,
            plateState: stateCode,
            quotebackId,
            createdDateUtc,
          }),
        });
        const { vins, vin, message, service } = response;
        // VIN === '' is valid case, we should show 2 types of error messages for the user, when API failed and when VIN is ''.
        if (!vins && !vin && vin !== '') {
          throw new Error(message || 'No VIN returned for licensePlate and stateCode');
        }
        let finalVins = vins;
        if (!vin && isEmpty(vins)) {
          finalVins = [''];
        }
        response = {
          vin: isEmpty(finalVins) ? vin : finalVins[0],
          service,
          vins,
        };
      } catch (e) {
        await context.updateValue('licensePlateError', true);
        response = undefined;
      }

      return response;
    },
  },
  {
    path: 'eligibilityCreativeIds',
  },
  {
    path: 'ymmzEligibilityCreativeIds',
  },
  {
    path: 'licensePlateError',
  },
  {
    path: 'conditionsQuestions',
    async resolve(match, context) {
      let response;
      const fallbackResponse = [];
      try {
        // Bypassing Gateway API for ALL condition questions requests to avoid double-caching, per EMO-1224
        response = await withMetrics(EdmundsPartnerOfferAPI, context).fetchJson('/v2/conditions/questions');
        if (isEmpty(response)) {
          response = fallbackResponse;
        }
      } catch (e) {
        response = fallbackResponse;
      }
      return response;
    },
  },
  {
    path: 'conditionsQuestions.e2e',
    async resolve(match, context) {
      let response;
      const fallbackResponse = [];
      try {
        response = await withMetrics(EdmundsPartnerOfferAPI, context).fetchJson('/v2/mock/conditions/questions');
        if (isEmpty(response)) {
          response = fallbackResponse;
        }
      } catch (e) {
        response = fallbackResponse;
      }
      return response;
    },
  },
  {
    path: transformedConditionsQuestionsPath.segment,
    async resolve(match, context) {
      const location = await context.resolveValue('location', PageModel);

      const questions = await context.resolveValue(buildPartnerOffersConditionsQuestionsPath(location));
      return transformQuestions(fillInPartner(questions, PARTNERS.CARMAX));
    },
  },
  {
    path: 'vin["{vin}"].eligibility',
    resolve({ vin }, context) {
      return getPartnerOfferEligibilityByVin(vin, context, APPRAISAL_OFFER_RENEWAL_CREATIVE_ID);
    },
  },
]);

export const PartnerOfferEntities = {
  Eligibility: PropTypes.shape({
    store: PropTypes.shape({
      name: PropTypes.string,
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      longitude: PropTypes.number,
      latitude: PropTypes.number,
    }),
    isEligible: PropTypes.bool,
    nearestStores: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  VinEligibility: PropTypes.shape({
    isEligibleVin: PropTypes.bool,
    isSquishVinValid: PropTypes.bool,
    squishStyles: VehicleVinEntities.SquishStyles,
  }),
  ConditionsQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      desc: PropTypes.string,
      category: PropTypes.string,
      answerType: PropTypes.string,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          desc: PropTypes.string,
          answerType: PropTypes.string,
          detailQuestions: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number,
              desc: PropTypes.string,
              answerType: PropTypes.string,
              answers: PropTypes.arrayOf(PropTypes.shape({})),
            })
          ),
        })
      ),
    })
  ),
  SkuAndOptions: PropTypes.shape({
    sku: PropTypes.string,
    availableOptions: PropTypes.arrayOf(PropTypes.string),
    standardOptions: PropTypes.arrayOf(PropTypes.string),
  }),
};
