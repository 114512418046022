import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, forEach, isEmpty, compact } from 'lodash';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { SEOModel } from 'client/data/models/seo';
import { PageModel } from 'client/data/models/page';
import { RequestModel } from 'client/data/models/request';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { updateModel } from 'client/data/luckdragon/redux/model-actions';
import { logger } from 'client/utils/isomorphic-logger';
import { getNonAmpUrl } from 'site-modules/shared/utils/url-utils';
import { ROBOTS_MAP, DEFAULT_TITLE, DEFAULT_DESCRIPTION } from 'site-modules/shared/constants/seo';

const HEAD_PATH = 'headContent';
export const SCHEMA_URL = 'https://schema.org';
export const SITE_URL = 'https://www.edmunds.com';

export const processAmpCanonicals = pathname => getNonAmpUrl(pathname);

export const ORGANIZATION_JSON_LD_OBJECT = {
  '@context': SCHEMA_URL,
  '@type': 'Organization',
  url: 'https://www.edmunds.com',
  legalName: 'Edmunds.com',
  logo: 'https://cdn.ed.edmunds-media.com/unversioned/images/logos/edmunds-logo-with-under-text-151x151.png',
  sameAs: [
    'https://www.facebook.com/edmunds',
    'https://plus.google.com/+edmunds',
    'https://www.pinterest.com/edmundsinc/',
    'https://www.linkedin.com/company/edmunds-com',
    'http://tumblr.edmunds.com/',
    'https://www.youtube.com/user/edmundsvideo',
    'https://www.instagram.com/edmundscars/',
  ],
};

const JSON_LD_BASE = [
  {
    '@context': SCHEMA_URL,
    '@type': 'WebSite',
    name: 'Edmunds',
    url: 'https://www.edmunds.com',
  },
];

export const generateCanonical = location => location && location.pathname && `${SITE_URL}${location.pathname}`;

export const generateRelLink = link => link && `${SITE_URL}${link}`;

function processRobots(options = {}) {
  const robotsOptions = ['max-image-preview:large'];
  forEach(ROBOTS_MAP, (value, key) => {
    if (options[key]) {
      robotsOptions.push(value);
    }
  });
  return robotsOptions;
}

const DEFAULT_KEYWORDS =
  'new car prices, used car pricing, auto reviews, car buying guide, used auto prices, car ratings, car comparisons, auto advice, car values, auto leasing, car invoice price, edmunds car price guide, edmonds, edmunds auto guide, edmonds.com, edmunds.com ';
const DEFAULT_SITE_NAME = 'Edmunds';
const DEFAULT_SITE_CONTENT = '@edmunds';
const DEFAULT_SITE_TYPE = 'website';
const ARTICLE_TYPE = 'article';
const DEFAULT_JSONLD = [];
const CONTENT_SUMMARY = 'summary';
const CONTENT_SUMMARY_LARGE_IMAGE = 'summary_large_image';
export const DEFAULT_IMAGE_SIZE = {
  HEIGHT: 200,
  WIDTH: 200,
};

export const DEFAULT_IMAGE_URL =
  'https://static.ed.edmunds-media.com/unversioned/images/logos/edmunds-logo-200x200.png';

const DEFAULT_IMAGE_ALT = 'Edmunds Logo';

export const DEFAULT_SEO_CONTEXT = {
  title: DEFAULT_TITLE,
  description: DEFAULT_DESCRIPTION,
  keywords: DEFAULT_KEYWORDS,
  jsonld: DEFAULT_JSONLD,
};

export const NOT_FOUND_SEO_CONTEXT = {
  title: 'Page Not Found | Edmunds.com',
  description: '',
  keywords: '',
  canonical: 'https://www.edmunds.com/404.html',
};

function addSSRTags(seoMarkup) {
  const result = [];
  seoMarkup.props.children.forEach(reactNode => {
    if (reactNode && reactNode.props) {
      result.push(React.cloneElement(reactNode, { 'data-ssr': true }));
    } else if (Array.isArray(reactNode)) {
      reactNode.forEach(innerNode => {
        if (innerNode && innerNode.props) {
          result.push(React.cloneElement(innerNode, { 'data-ssr': true }));
        }
      });
    }
  });
  return result;
}

function SEOWrapper({ serverRender, children }) {
  if (serverRender) {
    return addSSRTags(<Fragment>{children}</Fragment>);
  }

  /*
    The `return null;` below prevents re-rendering the <SEOWrapper> and its children on the client side.

    While PLAT continues working on a SPA re-think, we have added workarounds to update the page "title" and
    "description" with SHOP-118.  Please remove these updates if this component begins re-rendering
    on the client side in the future.

    These workarounds have been added in "updateSeoTags" methods of:
     - client/site-modules/inventory/pages/usurp/usurp.jsx
     - client/site-modules/car-buying-online/pages/srp/car-buying-online.jsx
   */
  return null;
}

function renderJsonLd(serverRender, isCrawler, ldJson, jsonldCrawlersOnly) {
  if (jsonldCrawlersOnly) {
    let scriptTag = null;

    if (isCrawler) {
      scriptTag = serverRender ? (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ldJson }} />
      ) : (
        <script type="application/ld+json">{ldJson}</script>
      );
    }

    return scriptTag;
  }

  const scriptTag = serverRender ? (
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: ldJson }} />
  ) : (
    <script type="application/ld+json">{ldJson}</script>
  );

  return scriptTag;
}

SEOWrapper.propTypes = {
  serverRender: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export class SEOHeadUI extends Component {
  static propTypes = {
    content: PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      keywords: PropTypes.string,
      canonical: PropTypes.string,
      jsonld: PropTypes.arrayOf(PropTypes.object),
      type: PropTypes.string,
      imageUrl: PropTypes.string,
      imageWidth: PropTypes.number,
      imageHeigth: PropTypes.number,
      next: PropTypes.string,
      prev: PropTypes.string,
      robots: PropTypes.object,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    serverRender: PropTypes.bool,
    assetsToPreload: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string.isRequired,
        as: PropTypes.string,
        rel: PropTypes.string,
      })
    ),
    isSearchBot: PropTypes.bool,
    isAkamaiCachePrefresh: PropTypes.bool,
    jsonldCrawlersOnly: PropTypes.bool,
    addOrganizationOnlyToAboutAndHomePage: PropTypes.bool,
  };

  static defaultProps = {
    content: DEFAULT_SEO_CONTEXT,
    serverRender: false,
    assetsToPreload: null,
    isSearchBot: false,
    isAkamaiCachePrefresh: false,
    jsonldCrawlersOnly: false,
    addOrganizationOnlyToAboutAndHomePage: false,
  };

  render() {
    const {
      content,
      location,
      assetsToPreload,
      serverRender,
      isSearchBot,
      isAkamaiCachePrefresh,
      jsonldCrawlersOnly,
      addOrganizationOnlyToAboutAndHomePage,
    } = this.props;

    if (!content) {
      return null;
    }

    const {
      title = DEFAULT_TITLE,
      description = DEFAULT_DESCRIPTION,
      keywords = DEFAULT_KEYWORDS,
      canonical = processAmpCanonicals(generateCanonical(location)),
      jsonld = DEFAULT_JSONLD,
      type,
      next,
      prev,
      image = {},
      robots,
      socialDescription,
      publishedTime,
      modifiedTime,
      articleAuthor,
    } = content;

    const imgAlt = isEmpty(image) ? DEFAULT_IMAGE_ALT : get(image, 'alt');

    const twitterCardContent = isEmpty(image) ? CONTENT_SUMMARY : CONTENT_SUMMARY_LARGE_IMAGE;
    const baseJsonLd = !addOrganizationOnlyToAboutAndHomePage
      ? [...JSON_LD_BASE, ORGANIZATION_JSON_LD_OBJECT]
      : JSON_LD_BASE;
    const ldJson = JSON.stringify(compact([...baseJsonLd, ...jsonld]));
    const isCrawler = isSearchBot || isAkamaiCachePrefresh;

    return (
      <SEOWrapper {...this.props}>
        <title>{title}</title>

        <meta name="title" content={title} />
        <meta name="description" content={description} />
        {keywords ? <meta name="keywords" content={keywords} /> : null}
        <meta name="robots" content={processRobots(robots).join(',')} />

        <meta property="fb:app_id" content={96106612846} />
        <meta property="fb:page_id" content={5798888989} />
        <meta property="og:site_name" content={DEFAULT_SITE_NAME} />
        <meta
          property="og:admins"
          content="6230134, 9700214, 300100577, 694037044, 100001443143026, 696964477, 561478297, 719501952, 10000004664664, 100000321489759"
        />
        <meta property="og:url" content={canonical} />
        <meta property="og:type" content={type || DEFAULT_SITE_TYPE} />
        {type === ARTICLE_TYPE ? (
          <Fragment>
            {publishedTime ? <meta property="article:published_time" content={publishedTime} /> : null}
            {modifiedTime ? <meta property="article:modified_time" content={modifiedTime} /> : null}
            {articleAuthor ? <meta property="article:author" content={articleAuthor} /> : null}
          </Fragment>
        ) : null}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={socialDescription || description} />
        <meta property="og:image" content={get(image, 'url', DEFAULT_IMAGE_URL)} />
        <meta property="og:image:width" content={get(image, 'width', DEFAULT_IMAGE_SIZE.WIDTH)} />
        <meta property="og:image:height" content={get(image, 'height', DEFAULT_IMAGE_SIZE.HEIGHT)} />
        {imgAlt ? <meta property="og:image:alt" content={imgAlt} /> : null}

        <meta name="twitter:site" content={DEFAULT_SITE_CONTENT} />
        <meta name="twitter:creator" content={DEFAULT_SITE_CONTENT} />
        <meta name="twitter:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={socialDescription || description} />
        <meta name="twitter:card" content={twitterCardContent} />

        <meta property="twitter:image" content={get(image, 'url', DEFAULT_IMAGE_URL)} />
        <meta property="twitter:image:width" content={get(image, 'width', DEFAULT_IMAGE_SIZE.WIDTH)} />
        <meta property="twitter:image:height" content={get(image, 'height', DEFAULT_IMAGE_SIZE.HEIGHT)} />
        {imgAlt ? <meta property="twitter:image:alt" content={imgAlt} /> : null}

        <meta name="theme-color" content="#0069bf" />

        <link rel="canonical" href={canonical} />
        {prev ? <link rel="prev" href={prev} /> : null}
        {next ? <link rel="next" href={next} /> : null}

        {assetsToPreload
          ? assetsToPreload.map(({ rel = 'preload', as = 'image', href, importance = 'high' }) =>
              rel === 'preload' ? (
                <link key={href} rel={rel} href={href} as={as} fetchpriority={importance} importance={importance} />
              ) : (
                <link key={href} rel={rel} href={href} />
              )
            )
          : null}
        {renderJsonLd(serverRender, isCrawler, ldJson, jsonldCrawlersOnly)}
      </SEOWrapper>
    );
  }
}

export const mapStateToProps = state => ({
  jsonldCrawlersOnly: !!get(state, 'featureFlags.jsonldCrawlersOnly'),
  addOrganizationOnlyToAboutAndHomePage: !!get(state, 'featureFlags["enable-seot-3616-move-org-schema"]'),
});

const stateToPropsConfig = {
  content: bindToPath(HEAD_PATH, SEOModel),
  assetsToPreload: bindToPath('server.preloadedAssets', PageModel),
  isSearchBot: bindToPath('isSearchBot', RequestModel),
  isAkamaiCachePrefresh: bindToPath('isAkamaiCachePrefresh', RequestModel),
};

export const SEOHead = connect(mapStateToProps)(connectToModel(SEOHeadUI, stateToPropsConfig));

const handlePreloadError = error => {
  logger('error', error);
  throw error;
};

const evaluateSEOContext = (seoContext, data, props) => {
  const { request, featureFlags } = data;

  if (featureFlags?.jsonldCrawlersOnly) {
    // For non-bots, default jsonld will be used
    // To avoid unintended data manipulation, we make a shallow copy
    let seo = { ...seoContext };

    if (typeof seoContext === 'function') {
      seo = seoContext(data, props);
    }

    const isCrawler = request?.isSearchBot || request?.isAkamaiCachePrefresh;

    if (!isCrawler) {
      // default json ld value
      // Not using DEFAULT_JSONLD since there could be
      // unintended data manipulation to its value
      seo.jsonld = [];
    }

    return seo;
  }

  if (typeof seoContext === 'function') {
    return seoContext(data, props);
  }

  return seoContext;
};

export const preloadSEO = (seoContext, store, props) => {
  const preloader = new ModelPreloader(store);
  preloader.set(HEAD_PATH, SEOModel, evaluateSEOContext(seoContext, store.getState(), props));

  return preloader.load().catch(handlePreloadError);
};

export const updateSEO = (seoContext, props) => (dispatch, getState) =>
  dispatch(updateModel(HEAD_PATH, SEOModel, evaluateSEOContext(seoContext, getState(), props)));
