/* eslint-disable global-require */
import { HTTP_OK, HTTP_ERROR_500, HTTP_NOT_FOUND, HTTP_GONE } from './utils/http-status';
import { notFound, explicit404Routes } from './site-modules/venom/routes/not-found';
import { error } from './site-modules/venom/routes/error';
import { gone } from './site-modules/venom/routes/gone';

const subdomainRoutesMap = {
  'test-microsite': {
    routes: () =>
      Array.prototype.concat(
        require('./site-modules/microsite/test-microsite/routes/demo').routes,
        require('./site-modules/microsite/test-microsite/routes/error').routesErrorDemo,
        require('./site-modules/microsite/test-microsite/routes/gone').routesGoneDemo,
        require('./site-modules/microsite/test-microsite/routes/not-found').routesNotFound // this MUST be the last require in this array
      ),
    routesNotFound: () => require('./site-modules/microsite/test-microsite/routes/not-found').routesNotFound,
    routesGone: () => require('./site-modules/microsite/test-microsite/routes/gone').routesGone,
    routesError: () => require('./site-modules/microsite/test-microsite/routes/error').routesError,
  },
  dealers: {
    routes: () =>
      Array.prototype.concat(
        require('./site-modules/venom/routes/error').routes,
        require('./site-modules/microsite/dealers/routes/dealer-center').testRoutes,
        require('./site-modules/microsite/dealers/routes/dealer-center').routes,
        require('./site-modules/microsite/dealers/routes/dealer-center').notFound // this MUST be the last require in this array
      ),
    routesNotFound: () => require('./site-modules/microsite/dealers/routes/dealer-center').notFound,
    routesGone: () => require('./site-modules/microsite/dealers/routes/dealer-center').gone,
    routesError: () => require('./site-modules/microsite/dealers/routes/dealer-center').error,
  },
};

const getSubdomainRoutes = (subdomain, routeType) => {
  if (subdomainRoutesMap[subdomain]) {
    return subdomainRoutesMap[subdomain][routeType]();
  }
  return null;
};

export const routes = subdomain => {
  const subdomainRoutes = getSubdomainRoutes(subdomain, 'routes');
  if (subdomainRoutes) {
    return subdomainRoutes;
  }
  return Array.prototype.concat(
    require('./site-modules/upper-funnel/routes/home').routes,
    explicit404Routes, // any explicit 404s will take precedence over other routes,
    require('./site-modules/venom/routes/error').routes,
    require('./site-modules/venom/routes/model-demo').routes,
    require('./site-modules/venom/routes/testpages').routes,
    require('./site-modules/sitemap/routes/sitemap').routes,
    require('./site-modules/appraiser/routes/appraiser').routes,
    require('./site-modules/appraisal/routes/appraisal').routes,
    require('./site-modules/price-checker/routes/price-checker').routes,
    require('./site-modules/dealerships/routes/dealer-details-page').routes,
    require('./site-modules/dealerships/routes/dealerships-landing').routes,
    require('./site-modules/dealerships/routes/dealer-content').routes,
    require('./site-modules/dealerships/routes/dealer-review').routes,
    require('./site-modules/consumer-ratings-reviews/routes/consumer-ratings-reviews').routes,
    require('./site-modules/used-cars-landing/routes/used-cars-for-sale').routes,
    require('./site-modules/car-reviews-landing/routes/car-reviews').routes,
    require('./site-modules/cpo/routes/cpo').routes,
    require('./site-modules/incentives/routes/incentives').routes,
    require('./site-modules/car-maintenance/routes/car-maintenance').routes,
    require('./site-modules/dealer-ico-widget/routes').routes,
    require('./site-modules/financing/routes/financing').routes,
    require('./site-modules/multi-offer/routes/multi-offer').indexAppRoutes, // The multi-offer index.app routes need to take precedence over editorial routes due to route conflict with research article routes.
    require('./site-modules/editorial/routes').routes,
    require('./site-modules/profile/routes/dashboard').routes,
    require('./site-modules/profile/routes/auth-action').routes,
    require('./site-modules/profile/routes/process-search').routes,
    require('./site-modules/car-buying/routes/car-buying').routes,
    require('./site-modules/health-check/routes/health-check').routes,
    require('./site-modules/comparator/routes/comparator').routes,
    require('./site-modules/examples/routes/examples').routes,
    require('./site-modules/inventory/routes/make-type').routes,
    require('./site-modules/make/routes/make-connect').routes,
    require('./site-modules/make/routes/make').routes,
    require('./site-modules/make/routes/make-lease-deals').routes,
    require('./site-modules/features-specs/routes/features-specs').routes,
    require('./site-modules/consumer-reviews/routes/consumer-reviews').routes,
    require('./site-modules/mpg/routes/mpg').routes,
    require('./site-modules/build-price/routes/build-price').routes,
    require('./site-modules/cost-to-own/routes/cost-to-own').routes,
    require('./site-modules/pictures/routes/pictures').routes,
    require('./site-modules/write-review/routes/write-review').routes,
    require('./site-modules/lead-form/routes').routes,
    require('./site-modules/long-term-road-test/routes/long-term-road-test').routes,
    require('./site-modules/new-cars-landing/routes/new-car-pricing').routes,
    require('./site-modules/inventory/routes/srp').routes,
    require('./site-modules/core-page/routes/style').routes,
    require('./site-modules/core-page/routes/highlights').routes,
    require('./site-modules/core-page/routes/connect').routes,
    require('./site-modules/inventory/routes/vdp').routes,
    require('./site-modules/upper-funnel/routes/type').routes,
    require('./site-modules/upper-funnel/routes/best').routes,
    require('./site-modules/upper-funnel/routes/subtype').routes,
    require('./site-modules/basic-ad-page-speed/routes/basic-ad-page-speed').routes,
    require('./site-modules/industry-center/routes/industry').routes,
    require('./site-modules/calculators/routes/calculators').routes,
    require('./site-modules/inventory/routes/self-reported-sale').routes,
    require('./site-modules/payments/routes/payments').routes,
    require('./site-modules/data-privacy-request/routes/data-privacy-request').routes,
    require('./site-modules/infographics/routes/index').routes,
    require('./site-modules/partners/routes/partners').routes,
    require('./site-modules/brand-landing/routes').routes,
    require('./site-modules/shared/routes/mmy').routes,
    require('./site-modules/wisdom-of-the-crowd/routes/deal').routes,
    require('./site-modules/upper-funnel/routes/shopcars').routes,
    require('./site-modules/widgets/routes').routes,
    require('./site-modules/car-buying-online/routes').routes,
    require('./site-modules/multi-offer/routes/multi-offer').routes,
    require('./site-modules/rss/routes/rss').routes,
    require('./site-modules/glossary/routes/glossary').routes,
    require('./site-modules/site-served-ad/routes/ads').routes,
    require('./site-modules/personalized-search/routes/personalized-search').routes,

    /**
     additional requires go here - one require per logical group of pages - e.g.
     require('./routes/editorial').routes
     each require should return an array of objects
     */
    require('./site-modules/venom/routes/not-found').routes // this MUST be the last require in this array
  );
};

const routesNotFound = subdomain => {
  const subdomainRoutesNotFound = getSubdomainRoutes(subdomain, 'routesNotFound');
  if (subdomainRoutesNotFound) {
    return subdomainRoutesNotFound;
  }
  return [
    require('./site-modules/multi-offer/routes/multi-offer').notFound,
    notFound, // this MUST be the last route
  ];
};

const routesGone = subdomain => {
  const subdomainRoutesGone = getSubdomainRoutes(subdomain, 'routesGone');
  if (subdomainRoutesGone) {
    return subdomainRoutesGone;
  }
  return [
    require('./site-modules/car-buying-online/routes/index').gone,
    require('./site-modules/inventory/routes/vdp').gone,
    gone, // this MUST be the last route
  ];
};

const routesError = subdomain => {
  const subdomainRoutesError = getSubdomainRoutes(subdomain, 'routesError');
  if (subdomainRoutesError) {
    return subdomainRoutesError;
  }
  return [error];
};

export function getRoutes(status = HTTP_OK, subdomain = null) {
  if (status === HTTP_GONE) {
    return routesGone(subdomain);
  }
  if (status >= HTTP_ERROR_500) {
    return routesError(subdomain);
  }
  if (status === HTTP_NOT_FOUND) {
    return routesNotFound(subdomain);
  }
  return routes(subdomain);
}

export function makeRouteParams(route) {
  const params = { path: route.path, component: route.page };
  if (route.exact) {
    params.exact = true;
  }
  return params;
}

export function getClientRedirects() {
  return [];
}
