import { makes, TWO_WORDS_MAKE_NAMES_MAP } from 'client/data/constants/active-makes';
import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

const getDdpPageDefinitionParams = match => ({
  ...match.params,
  make: TWO_WORDS_MAKE_NAMES_MAP[match.params.make] || match.params.make,
});

export const routes = [
  {
    path: `/dealerships/:make(all|${makes.join('|')})/:dealerState/:dealerCity/:logicalName/`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "car_inventory_ddp_map" */ '../pages/dealer/dealer-definition'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      getDdpPageDefinitionParams
    ),
    chunkName: 'car_inventory_ddp_map',
    enableLooseEtag: true,
  },
  {
    path: `/dealerships/:make(all|${makes.join('|')})/:dealerState/:dealerCity/:logicalName/offer/`,
    exact: true,
    page: getLoadablePage(
      () => import(/* webpackChunkName: "car_inventory_ddp_map_instant_offer" */ '../pages/dealer/dealer-offer'),
      // TODO: PLAT-1417 Do refactoring for routes using extra params
      getDdpPageDefinitionParams
    ),
    chunkName: 'car_inventory_ddp_map_instant_offer',
    enableLooseEtag: true,
  },
];
