import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: '/dealerships/dealer-content',
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "dealer-content" */ '../pages/dealer-content/dealer-content')
    ),
    chunkName: 'dealer-content',
    enableLooseEtag: true,
  },
];
