/**
 * Default Vehicle
 */
import { gql } from '@apollo/client'; // eslint-disable-line
import { reduce, get, isEmpty } from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { getDefaultYearUrl } from 'client/data/utils/default-year-core-4062';
import { FeatureFlagModel } from 'client/data/models/feature-flag';

// Return vehicle or default submodel
export function buildDefaultSubmodelPath({ make, model, year, submodel }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"]`
    : `makes["${make}"].models["${model}"].defaultSubmodelSlugAndPubState.years["${year}"]`;
}

export function buildMakeModelDefaultYear({ make, model }) {
  return `makes["${make}"].models["${model}"].defaultYear`;
}

function generateSlugs(items, slugName = 'slug') {
  if (items && typeof items === 'object') {
    return reduce(
      items,
      (result, value, key) => {
        if (typeof value === 'string') {
          return {
            ...result,
            [key]: value,
          };
        }
        const withSlugs = {
          ...result,
          [key]: {
            ...value,
            [slugName]: key,
          },
        };

        if (value.submodels) {
          withSlugs[key].submodels = generateSlugs(value.submodels);
        }
        return withSlugs;
      },
      {}
    );
  }
  return items;
}

export const VehicleDefaultModel = createModelSegment(
  'vehicle',
  [
    /**
     * @see buildDefaultSubmodelPath
     */
    {
      path: 'makes["{make}"].models["{model}"].defaultSubmodelSlugAndPubState.years["{year}"]',
      async resolve({ make, model, year }, context) {
        return withMetrics(EdmundsGraphQLFederation, context)
          .query(
            gql`
              query($make: String!, $model: String!, $year: Int!) {
                modelYears(makeSlug: $make, modelSlug: $model, year: $year) {
                  submodels(sortBy: { shareBySales: DESC }, page: { pageSize: 1 }) {
                    slug
                    publicationStates
                  }
                }
              }
            `,
            {
              make,
              model,
              year: parseInt(year, 10),
            }
          )
          .then(response => {
            const pubStates = {};
            get(response, 'modelYears[0].submodels[0].publicationStates', []).forEach(pub => {
              pubStates[pub] = true;
            });

            return { slug: get(response, 'modelYears[0].submodels[0].slug'), pubStates };
          });
      },
    },
    /**
     * @see buildDefaultSubmodelPath
     */
    {
      path: 'makes["{makeSlug}"].models["{modelSlug}"].submodels["{submodelSlug}"].years',
      async resolve({ makeSlug, modelSlug, submodelSlug }, context) {
        const submodelPath = `makes["${makeSlug}"].models["${modelSlug}"].submodels["${submodelSlug}"]`;
        return context.resolveValue(submodelPath).then(async submodel => {
          if (submodel) {
            const url = `/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/submodels/${submodelSlug}/years/`;
            const extendedApiCache = await context.resolveValue('extendedApiCache', FeatureFlagModel);

            return withMetrics(EdmundsAPI, context)
              .fetchJson(url, { timeout: 1000, cache: extendedApiCache ? 'force-cache' : undefined }) // Added for VDP and Core Cache
              .then(({ results }) => generateSlugs(results, 'year'));
          }

          return {};
        });
      },
    },
    /**
     * Example: https://qa-21-www.edmunds.com/gateway/api/coreresearch/v2/defaultyear/honda/civic
     * @see buildMakeModelDefaultYear
     */
    {
      path: 'makes["{makeSlug}"].models["{modelSlug}"].defaultYear',
      async resolve({ makeSlug, modelSlug }, context) {
        const url = await getDefaultYearUrl({ context, makeSlug, modelSlug });
        const extendedApiCache = await context.resolveValue('extendedApiCache', FeatureFlagModel);

        return withMetrics(EdmundsAPI, context)
          .fetchJson(url, { timeout: 1000, cache: extendedApiCache ? 'force-cache' : undefined }) // Added for VDP and Core Cache
          .then(years => {
            if (isEmpty(years)) {
              return null;
            }

            return years.results.year || years.results.defaultYear;
          });
      },
    },
  ],
  true // allowMergeToExisting
);
