export const CARMAX = 'CarMax Inc.';
export const SHIFT = 'Shift';
export const CARVANA = 'Carvana';
export const DRIVEWAY = 'Driveway';
export const AUTONATION = 'AutoNation';
export const JAKE_SWEENEY_EXPRESS = 'Jake Sweeney Express - Parent Account';
export const RUBYCAR = 'RubyCar';

export const BUY_ONLINE_DEALERS = [CARMAX, SHIFT, CARVANA, DRIVEWAY, JAKE_SWEENEY_EXPRESS, RUBYCAR];
export const DEALERS_WEB_SITES = {
  [CARMAX]: { webSite: 'https://www.carmax.com' },
  [SHIFT]: { webSite: 'https://shift.com' },
  [CARVANA]: { webSite: 'https://carvana.com' },
  [JAKE_SWEENEY_EXPRESS]: { webSite: 'https://express.jakesweeney.com' },
  [RUBYCAR]: { webSite: 'https://www.ruby-car.com' },
};
export const BOL_VDP_DEALERS = [CARMAX, DRIVEWAY, RUBYCAR];

export const DELIVERY_TEXT = {
  [RUBYCAR]: '100% Online. Delivery to your door',
};

export const RETAILER_OFFERS = {
  [CARMAX]: {
    copy: [
      {
        text: 'Stress-free online checkout',
        icon: 'icon-laptop',
        className: 'mt-md-0_25',
      },
      {
        text: '10-day return period',
        iconImg: '/img/car-buying/buy-online/icons/u_turn_icon.png',
        className: 'mb-md-0_25',
      },
      { text: 'Express Pickup ‡', icon: 'icon-transit' },
    ],
    logo: '/img/car-buying/buy-online/dealer-logo/carmax_logo.svg',
    logoHeight: 24,
    ctaTitle: 'Buy Online',
  },
  [CARVANA]: {
    copy: [
      { text: 'Stress-free online checkout' },
      { text: '7-day return period' },
      { text: 'Delivery or Express Pickup ‡' },
    ],
    logo: '/img/car-buying/buy-online/dealer-logo/carvana_logo.png',
    logoHeight: 24,
    ctaTitle: 'Get It Delivered',
  },
  [SHIFT]: {
    copy: [{ text: 'Stress-free online checkout' }, { text: '7-day return period' }, { text: 'Delivery ‡' }],
    logo: '/img/car-buying/buy-online/dealer-logo/shift_logo.svg',
    logoHeight: 24,
    ctaTitle: 'Get It Delivered',
  },
  [DRIVEWAY]: {
    copy: [
      {
        text: 'Stress-free online checkout',
        icon: 'icon-laptop',
        className: 'mt-md-0_25',
      },
      {
        text: '7-day return period',
        iconImg: '/img/car-buying/buy-online/icons/u_turn_icon.png',
        className: 'mb-md-0_25',
      },
      { text: 'Delivery or Express Pickup ‡', icon: 'icon-transit' },
    ],
    logo: '/img/car-buying/buy-online/dealer-logo/driveway_logo.png',
    logoHeight: 24,
    logoWidth: 96,
    ctaTitle: 'Get It Delivered',
  },
  [AUTONATION]: {
    copy: [
      { text: 'Introducing 6 or 12-month Micro Leases' },
      { text: 'Wide selection of vehicles' },
      { text: 'Exclusively available online' },
    ],
    logo: '/img/car-buying/buy-online/dealer-logo/autonation_logo.jpg',
    logoHeight: 41,
    ctaTitle: 'Explore Micro Leasing',
    ctaClasses: 'btn-md mobility fw-medium size-16',
  },
  [JAKE_SWEENEY_EXPRESS]: {
    copy: [
      { text: 'Stress-free online checkout' },
      { text: '3-day return period' },
      { text: 'Delivery or Express Pickup ‡' },
    ],
    logo: '/img/car-buying/buy-online/dealer-logo/JakeSweeneyExpress36.png',
    logoHeight: 36,
    ctaTitle: 'Get It Delivered',
  },
  [RUBYCAR]: {
    copy: [
      {
        text: `${DELIVERY_TEXT[RUBYCAR]} ‡`,
        icon: 'icon-laptop',
        className: 'mt-md-0_25',
      },
      { text: 'Single owner, Avis-certified vehicles', icon: 'icon-user3' },
      {
        text: '24-month / 24,000 mile warranty',
        iconImg: '/img/car-buying/buy-online/icons/certified-verified-check.png',
      },
    ],
    logo: '/img/car-buying/buy-online/dealer-logo/rubycar_logo.png',
    logoHeight: 30,
    logoWidth: 114,
    ctaTitle: 'Get It Delivered',
  },
};
