import 'isomorphic-fetch';

import { has } from 'lodash';
import { ApolloClient, HttpLink, from } from '@apollo/client'; // eslint-disable-line
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'; // eslint-disable-line
import { RetryLink } from '@apollo/client/link/retry'; // eslint-disable-line
import hash from 'hash.js/lib/hash/sha/256';
import { ClientConfig } from 'client/configuration';
import { IS_NODE, ENVIRONMENT_URL } from 'client/utils/environment';
import { HTTP_REQUEST_TIMEOUT } from 'client/utils/http-status';
import { NoCache } from './cache';

function sha256(str) {
  return hash()
    .update(str)
    .digest('hex');
}
const DEFAULT_SERVER_SIDE_TIMEOUT_MS = 700;
const SERVER_SIDE_RETRIES = 1;
const SERVER_SIDE_ATTEMPTS = SERVER_SIDE_RETRIES + 1;
const ABSOLUTE_URL_PATTERN = /^https?:\/\//i;
const PERSISTED_QUERY_ENABLED =
  process.env.PERSISTED_QUERY_ENABLED === 'true' || process.env.PERSISTED_QUERY_ENABLED === undefined;

// no caching at client level, will be provided at higher level
const fetchPolicy = 'no-cache';

/**
 * @param apolloError - the error that needs to be handled
 * @param query - the graph ql query
 * @param variables - the variables used in the graphql query
 * @return {Error}
 */
function transformErrorMetadata(apolloError, query, variables) {
  const error = apolloError;

  if (has(apolloError, 'networkError.response.url')) {
    error.apiUrl = apolloError.networkError.response.url;
    error.networkError.response = 'REMOVED-IN-ERROR-HANDLING';
    error.networkError.bodyText = 'REMOVED-IN-ERROR-HANDLING';
  }
  if (has(apolloError, 'networkError.type')) {
    if (apolloError.networkError.type.match('timeout')) {
      // networkError.type is typically 'request-timeout' or 'body-timeout'
      error.status = HTTP_REQUEST_TIMEOUT;
    }
  }
  error.queryVars = variables;
  return error;
}

/**
 * Returns true if GraphQL timeout is disabled, false by default
 * Provides developers an option to disable GraphQL timeout in local environment in case of slow connection
 *
 * @returns {Boolean}
 */
function isGraphqlTimeoutDisabled() {
  return ClientConfig.get('graphqlTimeoutDisabled', false);
}

function createApolloClient(uri) {
  // https://github.com/bitinn/node-fetch#options
  const fetchOptions = {
    timeout: isGraphqlTimeoutDisabled()
      ? 0 // req/res timeout in ms, it resets on redirect. 0 to disable (OS limit applies).
      : ClientConfig.get('graphqlDefaultTimeoutMs', DEFAULT_SERVER_SIDE_TIMEOUT_MS),
    method: 'GET',
  };

  const linkArr = [
    // Retry logic sits as first in the chain to handle any connection errors
    new RetryLink({
      // https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-retry
      delay: {
        initial: 0, // The number of milliseconds to wait before attempting the first retry.
        max: 0, // The maximum number of milliseconds that the link should wait for any retry.
        jitter: false, // Whether delays between attempts should be randomized.
      },
      attempts: {
        max: SERVER_SIDE_ATTEMPTS, // The max number of times to try a single operation before giving up.
      },
    }),

    // conditional logic to add the persisted query link as second in the chain, before HttpLink
    ...(PERSISTED_QUERY_ENABLED ? [createPersistedQueryLink({ sha256, useGETForHashedQueries: true })] : []),

    // the final part of the chain is the call out to GraphQL
    new HttpLink({
      uri,
      // https://www.apollographql.com/docs/link/links/http.html#options
      // fetchOptions: any overrides of the fetch options argument to pass to the fetch call
      fetchOptions,
      // https://www.apollographql.com/docs/react/api/link/apollo-link-http/#customizing-fetch
      // creating a simple fetch pass-through so we can log the graphql request for local dev
      fetch: (url, options) => {
        if (IS_NODE) {
          global.logger.debug(`[GRAPHQL] fetch ${url.slice(0, 200)}`);
        }
        return fetch(url, options);
      },
    }),
  ];

  return new ApolloClient({
    // Using `from` for additive link chain composition
    // https://www.apollographql.com/docs/react/api/link/introduction/#additive-composition
    link: from(linkArr),
    cache: new NoCache(),
  });
}

function unwrapResponse(response) {
  return response.data;
}

class GraphQLClient {
  constructor(graphUrl) {
    this.graphUrl = graphUrl;
    this.client = null;
  }

  query(query, variables = {}, options = {}) {
    if (!this.client) {
      this.client = createApolloClient(this.graphUrl());
    }

    const { headers } = options;

    return this.client
      .query({
        ...(headers && { context: { headers } }),
        query,
        variables,
        fetchPolicy,
      })
      .then(unwrapResponse)
      .catch(apolloError => {
        throw transformErrorMetadata(apolloError, query, variables);
      });
  }
}

export const EdmundsGraphQLFederation = new GraphQLClient(() => {
  const graphQLUrl = ClientConfig.get('edmundsGraphQLFederationUrl');

  if (IS_NODE && !ABSOLUTE_URL_PATTERN.test(graphQLUrl)) {
    return `${ENVIRONMENT_URL}${graphQLUrl}`;
  }

  return graphQLUrl;
});
