import { logger } from 'client/utils/isomorphic-logger';
import { get } from 'lodash';
import { getReduxModelStateFromStore } from './redux-model';

function updatePaths(pathsToUpdate, modelState, timing) {
  return Promise.all(
    pathsToUpdate.map(pathInfo => modelState.update(pathInfo.path, pathInfo.segment, pathInfo.value, timing))
  );
}

function resolvePaths(pathsToResolve, modelState, timing) {
  return Promise.all(pathsToResolve.map(pathInfo => modelState.resolve(pathInfo.path, pathInfo.segment, timing)));
}

/**
 * A preloader that assists with updating/resolving multiple model paths so they are preloaded into redux.
 *
 * @param {Object} reduxStore - The redux store where the model will be stored.
 * @param debugId
 */
export function ModelPreloader(reduxStore, { debugId } = {}) {
  let numLoadCalls = 1;
  let id;
  let pathsToUpdate = [];
  let pathsToResolve = [];

  /**
   * Marks a model path to be updated with the given value.
   *
   * @param {String} path - The path to update.
   * @param {ModelSegment} segment - The segment that contains the path.
   * @param {*} value - The value to store in the path.
   */
  this.set = function set(path, segment, value) {
    pathsToUpdate.push({ path, segment, value });
  };

  /**
   * Marks a model path to be resolved.
   *
   * @param {String} path - The path to resolve.
   * @param {ModelSegment} segment - The segment that contains the path.
   */
  this.resolve = function resolve(path, segment) {
    pathsToResolve.push({ path, segment });
  };

  /**
   * Preloads the model data into the store.
   *
   * All paths marked with set will be updated first, followed by path marked with resolve. This is done so any data
   * stored with set will be available to the path resolvers.
   */
  this.load = function load() {
    const modelState = getReduxModelStateFromStore(reduxStore);
    const reduxState = reduxStore.getState();
    const debugModelPreloader = get(reduxState, 'featureFlags.debugModelPreloader');

    if (debugModelPreloader) {
      id = id || `${debugId ? `${debugId}-` : ''}${Math.floor(Math.random() * 1000)}`;
      logger('debug', `\x1b[90mModelPreloader{${id}} - load() call #${numLoadCalls} was initiated\x1b[0m`);
    }
    const error = new Error();
    const callStack = error.stack.split('at ').slice(2, 4); // return line at index 2 and 3 (third and fourth lines in callstack)
    // sometimes the line at index 2 alone has what we need - f.e.
    //   Object.preload (/usr/src/app/client/site-modules/shared/routes/mmy.js:191:24)
    // other times both line at index 2 and index 3 are useful - f.e.
    //   VehiclePreloader.load (/usr/src/app/client/site-modules/shared/utils/core-page/vehicle-preloader-v2.jsx:90:22)
    //   preloadToExecute (/usr/src/app/client/site-modules/core-page/pages/new-core/new-core-definition.jsx:444:22)
    const timing = {
      depth: null,
      duration: null, // init prop here to preserve the property order
      startTime: Date.now(),
      endTime: null,

      callStack,
    };

    return updatePaths(pathsToUpdate, modelState, timing)
      .then(() => resolvePaths(pathsToResolve, modelState, timing))
      .then(() => {
        pathsToUpdate = [];
        pathsToResolve = [];
        return modelState;
      })
      .finally(() => {
        timing.endTime = Date.now();
        timing.duration = timing.endTime - timing.startTime;
        const profilerEnabled = reduxState?.featureFlags?.profiler;
        if (profilerEnabled) {
          reduxStore.dispatch({
            type: 'PROFILER_ACTION_PRELOADER_UPDATE',
            timing,
            requestStartTime: reduxState.request.startTime,
          });
        }
        if (debugModelPreloader) {
          logger('debug', `\x1b[90mModelPreloader{${id}} - load() call #${numLoadCalls} has completed\x1b[0m`);
          numLoadCalls += 1;
        }
      });
  };
}
