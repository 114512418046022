import { getLoadablePage } from 'site-modules/shared/components/loadable-page/loadable-page';

export const routes = [
  {
    path: `/best/*`,
    exact: true,
    page: getLoadablePage(() =>
      import(/* webpackChunkName: "car_type" */ '../pages/best-rankings/best-rankings-definition').then(page =>
        page.default({})
      )
    ),
    chunkName: 'car_type',
  },
];
