/**
 * There exists is a $partial supported vehicle-v2.js with unnecessary context.resolves removed.
 * Please consider using that for new paths and changes. Also consider migrating your components to the
 * new models. SEE https://edmunds.atlassian.net/browse/CORE-2667 for details.
 */
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { gql } from '@apollo/client'; // eslint-disable-line
import {
  reduce,
  set,
  get,
  pick,
  every,
  sortBy,
  isArray,
  findKey,
  findLast,
  forEach,
  partial,
  flow,
  filter,
  map,
  toArray,
  isEmpty,
  flatten,
} from 'lodash';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { EdmundsAPI } from 'client/data/api/api-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { HTTP_NOT_FOUND } from 'client/utils/http-status';
import { API_RESPONSE_STATUS_TRACKING } from 'site-modules/shared/utils/appraiser/api-response-tracking';
import { isNewState, isDisabledState } from 'site-modules/shared/utils/publication-states';
import { sortByName } from 'site-modules/shared/utils/sorting';
import { getLatestYears } from 'site-modules/shared/utils/date-utils';
import { filterDiscontinuedStyles } from 'site-modules/shared/utils/vehicle-utils';
import { getQueryTypeStringModelYears } from 'site-modules/shared/utils/query-type-mapping';
import { logger } from 'client/utils/isomorphic-logger';
import { PUB_STATES } from 'client/constants/pub-states';
import { makeNiceName } from 'site-modules/shared/utils/nice-name';
import { getDefaultYearUrl } from 'client/data/utils/default-year-core-4062';
import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { EventToolbox } from 'client/utils/event-toolbox';
import { formatAllTmvBandsUrl } from 'client/data/utils/format-urls';
import { filterFleetTrims } from 'site-modules/shared/utils/core-page/fleet-trims';
import { FeatureFlagModel } from 'client/data/models/feature-flag';

import { featuresMap } from './car-features-map';
import { VisitorModel } from './visitor';

const Prices = PropTypes.shape({
  topMakeShareStyle: PropTypes.shape({
    styleId: PropTypes.number,
    NEW: PropTypes.number,
    USED: PropTypes.number,
  }),
});

const PubStates = PropTypes.shape({
  NEW: PropTypes.bool,
  USED: PropTypes.bool,
  NEW_USED: PropTypes.bool,
  PRE_PROD: PropTypes.bool,
});

const Make = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  pubStates: PubStates.isRequired,
  slug: PropTypes.string.isRequired,
  adTargetId: PropTypes.string,
});

const Makes = PropTypes.arrayOf(Make);

const MakeModel = PropTypes.shape({
  name: PropTypes.string.isRequired,
  pubStates: PubStates.isRequired,
  make: Make.isRequired,
  modelLinkCode: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  adTargetId: PropTypes.string,
  prices: Prices,
});

const MakeModelSubmodel = PropTypes.shape({
  name: PropTypes.string.isRequired,
  pubStates: PubStates.isRequired,
  make: Make.isRequired,
  model: MakeModel.isRequired,
  slug: PropTypes.string.isRequired,
  adTargetId: PropTypes.string,
});

const ModelYear = PropTypes.shape({
  makeName: PropTypes.string,
  modelName: PropTypes.string,
  year: PropTypes.number,
});

const ModelYears = PropTypes.arrayOf(ModelYear);

const MakeModelSubmodelYear = PropTypes.shape({
  id: PropTypes.number.isRequired,
  pubStates: PubStates.isRequired,
  make: Make.isRequired,
  model: MakeModel.isRequired,
  modelYearId: PropTypes.number.isRequired,
  submodels: MakeModelSubmodel.isRequired,
  year: PropTypes.string.isRequired,
});

const MakeModelSubmodelsYear = PropTypes.shape({
  id: PropTypes.number.isRequired,
  pubStates: PubStates.isRequired,
  make: Make.isRequired,
  model: MakeModel.isRequired,
  modelYearId: PropTypes.number.isRequired,
  submodels: PropTypes.arrayOf(MakeModelSubmodel).isRequired,
  year: PropTypes.string.isRequired,
});

const PopularStyles = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })
);

const StyleSpecifications = PropTypes.shape({
  epaCombinedMPG: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const StyleEditorialReviews = PropTypes.shape({
  ratings: PropTypes.shape({
    rating: PropTypes.number,
  }),
});

const StyleConsumerReviewsRatings = PropTypes.shape({
  averageRating: PropTypes.number,
  reviewsCount: PropTypes.number,
});

const StyleEstimatedPricing = PropTypes.shape({
  estimatedSavings: PropTypes.number,
});

const StyleInsurancePrices = PropTypes.shape({
  monthlyValue: PropTypes.number,
});

const ConditionPricingValues = PropTypes.shape({
  current: PropTypes.shape({}),
  upper: PropTypes.shape({}),
  lower: PropTypes.shape({}),
});

const StyleMpg = PropTypes.shape({
  id: PropTypes.number,
  engineFuelType: PropTypes.string,
  name: PropTypes.string,
  trimName: PropTypes.string,
  submodelName: PropTypes.string,
  mpgData: PropTypes.shape({
    combined: PropTypes.string,
    city: PropTypes.string,
    highway: PropTypes.string,
  }),
});

const NewTmv = PropTypes.shape({
  totalWithOptions: PropTypes.shape({
    baseMSRP: PropTypes.number,
    tmv: PropTypes.number,
  }),
  incentivesAndRebates: PropTypes.number,
});

const StylePricing = PropTypes.shape({
  estimatedPricing: StyleEstimatedPricing,
  newTmv: NewTmv,
  usedTmv: PropTypes.shape({
    baseMSRP: PropTypes.number,
    tmv: PropTypes.number,
    usedTmvRetail: PropTypes.number,
    usedPrivateParty: PropTypes.number,
    withoutOptions: PropTypes.shape({
      OUTSTANDING: ConditionPricingValues,
      CLEAN: ConditionPricingValues,
      AVERAGE: ConditionPricingValues,
      ROUGH: ConditionPricingValues,
    }),
  }),
});

const StyleProps = {
  id: PropTypes.number.isRequired,
  makeName: PropTypes.string.isRequired,
  makeSlug: PropTypes.string.isRequired,
  modelName: PropTypes.string.isRequired,
  modelSlug: PropTypes.string.isRequired,
  trimName: PropTypes.string,
  trim: PropTypes.string,
  name: PropTypes.string.isRequired,
  numberOfSeats: PropTypes.number,
  pricing: StylePricing,
  publicationState: PropTypes.string.isRequired,
  editorialReviews: StyleEditorialReviews,
  year: PropTypes.number.isRequired,
  specifications: StyleSpecifications,
  consumerReviewsRatings: StyleConsumerReviewsRatings,
};

const Style = PropTypes.shape({
  ...StyleProps,
  submodels: MakeModelSubmodel,
});

const StyleWithoutSubmodels = PropTypes.shape(StyleProps);

const Styles = PropTypes.arrayOf(Style);

const TrimStylePrice = PropTypes.shape({
  baseMSRP: PropTypes.number,
  usedTmvRetail: PropTypes.number,
  usedPrivateParty: PropTypes.number,
  usedTradeIn: PropTypes.number,
});

const TrimStyleFeatures = PropTypes.shape({
  transmissionType: PropTypes.string.isRequired,
  bodyType: PropTypes.string.isRequired,
  bedLengthInFeet: PropTypes.string,
  displacement: PropTypes.string.isRequired,
  driveType: PropTypes.string.isRequired,
  engineFuelType: PropTypes.string.isRequired,
});

const TrimStyleEntity = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
  make: Make,
  model: MakeModel,
  year: PropTypes.number,
  publicationState: PropTypes.string,
  submodels: PropTypes.arrayOf(MakeModelSubmodel),
  price: TrimStylePrice,
  trimFeatures: TrimStyleFeatures,
});

const TrimNationalPrices = PropTypes.objectOf(
  PropTypes.shape({
    baseMSRP: PropTypes.number,
    baseInvoice: PropTypes.number,
    deliveryCharges: PropTypes.number,
    tmv: PropTypes.number,
    estimateTmv: PropTypes.number,
    tmvRecommendedRating: PropTypes.number,
  })
);

const Vehicle = PropTypes.shape({
  make: PropTypes.shape({
    name: PropTypes.string,
    niceName: PropTypes.string,
  }),
  model: PropTypes.shape({
    name: PropTypes.string,
    niceName: PropTypes.string,
  }),
  modelYear: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
  }),
  style: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  type: PropTypes.shape({
    niceName: PropTypes.string,
  }),
  publicationState: PropTypes.string,
});

const TrimEntity = PropTypes.shape({
  trimName: PropTypes.string,
  trimSlug: PropTypes.string,
  styles: PropTypes.arrayOf(TrimStyleEntity),
  nationalPrices: TrimNationalPrices,
});

const Submodel = PropTypes.shape({
  pubStates: PubStates.isRequired,
  make: Make.isRequired,
  model: MakeModel.isRequired,
  name: PropTypes.string.isRequired,
  years: PropTypes.arrayOf(MakeModelSubmodelYear).isRequired,
});

const ModelYearsByMakeId = PropTypes.arrayOf(
  PropTypes.shape({
    makeName: PropTypes.string,
    makeNiceName: PropTypes.string,
    modelName: PropTypes.string,
    modelNiceName: PropTypes.string,
    year: PropTypes.number,
    edTypeCategories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
  })
);

const Colors = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    manufactureOptionCode: PropTypes.string,
    name: PropTypes.string,
    properties: PropTypes.shape({
      rgb: PropTypes.string,
    }),
  })
);

const Options = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string,
    oemCode: PropTypes.string,
  })
);

const VehicleFeatures = PropTypes.shape({
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          oemCode: PropTypes.string,
        })
      ),
      price: PropTypes.shape({
        usedPrivateParty: PropTypes.number,
        usedTmvRetail: PropTypes.number,
        usedTradeIn: PropTypes.number,
      }),
    })
  ),
  colors: Colors,
  partnerMapping: PropTypes.shape({
    chromeIds: PropTypes.arrayOf(PropTypes.string),
  }),
  engine: PropTypes.shape({
    name: PropTypes.string,
    cylinders: PropTypes.number,
    size: PropTypes.number,
  }),
  transmissionType: PropTypes.string,
});

const GenericFeature = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  niceName: PropTypes.string,
  niceId: PropTypes.string,
  displayName: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
});

const GenericFeatures = PropTypes.objectOf(PropTypes.arrayOf(GenericFeature));

const VehicleStyleIds = PropTypes.shape({
  newDefaultStyleId: PropTypes.number,
  usedDefaultStyleId: PropTypes.number,
  mostPopularStyleId: PropTypes.number,
});

const VehicleYear = PropTypes.shape({
  id: PropTypes.number,
  year: PropTypes.string,
  pubStates: PubStates,
  make: Make,
  model: MakeModel,
  modelYearId: PropTypes.number,
  modelLinkCode: PropTypes.string,
  submodels: PropTypes.arrayOf(MakeModelSubmodel),
  newDefaultStyleId: PropTypes.number,
  usedDefaultStyleId: PropTypes.number,
  mostPopularStyleId: PropTypes.number,
  mostPopularSubmodel: PropTypes.number,
});

const LatestVehicleYears = PropTypes.arrayOf(VehicleYear);

export const VehicleEntities = {
  Make,
  Makes,
  MakeModel,
  MakeModelSubmodel,
  MakeModelSubmodelYear,
  MakeModelSubmodelsYear,
  PubStates,
  Prices,
  TrimEntity,
  TrimStyleEntity,
  TrimStylePrice,
  TrimStyleFeatures,
  TrimNationalPrices,
  PopularStyles,
  Styles,
  Style,
  StylePricing,
  StyleConsumerReviewsRatings,
  StyleEditorialReviews,
  StyleEstimatedPricing,
  StyleSpecifications,
  StyleInsurancePrices,
  StyleWithoutSubmodels,
  StyleMpg,
  NewTmv,
  Vehicle,
  Submodel,
  ModelYears,
  ModelYearsByMakeId,
  Colors,
  Options,
  VehicleFeatures,
  GenericFeatures,
  GenericFeature,
  LatestVehicleYears,
  VehicleStyleIds,
};

/**
 * These subset of features are used on the core page in:
 * - site-modules/core-page/components/more-about/mpg-section/mpg-section.jsx
 * - site-modules/core-page/components/more-about/vehicle-performance/vehicle-performance.jsx
 * - site-modules/core-page/components/features-specs/features-specs.jsx
 * {string}
 */
const FEATURES_SUBSET = (() =>
  [
    'features.Drive Train.Transmission',
    'features.Drive Train.Drive type',
    'features.Engine.Base engine size',
    'features.Engine.Base engine type',
    'features.Engine.Horsepower',
    'features.Fuel.Combined MPG',
    'features.Fuel.EPA combined MPG',
    'features.Fuel.Fuel type',
    'features.Fuel.EPA mileage est. (cty/hwy)',
    'features.Fuel.EPA city/highway MPG',
    'features.Fuel.EPA city/highway MPGe',
    'features.Fuel.EPA kWh/100 mi',
    'features.Fuel.EPA Electricity Range',
    'features.Fuel.EPA electricity range',
    'features.Fuel.EPA Time to charge battery (at 240V)',
    'features.Fuel.EPA time to charge battery (at 240V)',
    'features.Measurements',
    'price.baseMSRP',
    'totalSeating',
  ].join(','))();

export const GENERIC_FEATURES_PATH =
  '/vehicle/v3/consumergenericfeatures/?view=custom%2CfieldsInclude%3Aname%2CsimpleName%2Ctags%2CniceId';

export function buildMmysMsrpPricesPath({ make, model, year, submodel }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].msrpPriceRanges`;
}

export function buildMmyFeaturesPath({ make, model, year, submodel }) {
  return submodel
    ? `features.makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].styles`
    : `features.makes["${make}"].models["${model}"].defaultSubmodel.years["${year}"].styles`;
}

export function buildMmyTrimsPath({ make, model, year, submodel }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].year["${year}"].trims`
    : `makes["${make}"].models["${model}"].year["${year}"].trims`;
}

export function buildTrimsAndPricePath({ make, model, year, submodel }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].year["${year}"].trimsAndPrice`;
}

export function buildSubmodelsPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].submodelsByYear["${year}"]`;
  // return `makes["${make}"].models["${model}"].submodels.years["${year}"]`;
}

export function buildSubmodelYearsPath({ make, model }) {
  return `makes["${make}"].models["${model}"].submodel.years`;
}

export function buildYearsByMMYSPath({ make, model, submodel }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years`;
}

export function buildActiveVehiclePath({ make, model }) {
  return `makes["${make}"].models["${model}"].activeVehicle`;
}

export function buildDefaultVehiclePath({ make, model }) {
  return `makes["${make}"].models["${model}"].activeVehicle.withReview`;
}

export function buildDefaultSubmodelPath({ make, model }) {
  return `makes["${make}"].models["${model}"].defaultSubmodel.latestNonPreprodVehicle`;
}

export function buildVehiclePath({ make, model, year, submodel }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"]`
    : `makes["${make}"].models["${model}"].defaultSubmodel.years["${year}"]`;
}

export function buildNoSubmodelVehiclePath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"]`;
}

export function buildEstimatedStyleIdsPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].estimatedStyleIds`;
}

export function buildPopularStylesPath({ make, model, year, submodel }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].styles`
    : `makes["${make}"].models["${model}"].defaultSubmodel.years["${year}"].styles`;
}

export function buildStylesPath({ make, model, year, submodel }) {
  return submodel
    ? `makes["${make}"].models["${model}"].submodels["${submodel}"].styles.years["${year}"]`
    : `makes["${make}"].models["${model}"].years["${year}"].styles`;
}

export function buildModelsPath({ make }) {
  return `makes["${make}"].models`;
}

export function buildModelYearsPath(make) {
  return `makes["${make}"].modelYears`;
}

export function buildVehicleStylePath({ styleId }) {
  return `styles.${styleId}`;
}

export function buildVehicleStyleInsurancePricesPath(styleId) {
  return `${buildVehicleStylePath({ styleId })}.insurancePrices`;
}

export function buildMakeModelPath({ make, model }) {
  return `makes["${make}"].models["${model}"]`;
}

export function buildMakeYearsPath(make) {
  return `makes["${make}"].years`;
}

export function buildYearMakesPath(year, pubStates = [PUB_STATES.NEW, PUB_STATES.NEW_USED, PUB_STATES.USED]) {
  return `year["${year}"].pubStates["${pubStates.sort().join()}"].makesList`;
}

export function buildMakeModelYearsPath({ make, model }) {
  return `makes["${make}"].models["${model}"].years`;
}

export function buildMakeModelDefaultYear({ make, model }) {
  return `makes["${make}"].models["${model}"].defaultYear`;
}

export function buildLatestVehiclesPath({ make, model }) {
  return `makes["${make}"].models["${model}"].latestVehicles`;
}

export function buildMakeYearSubmodelsPath({ make, year }) {
  return `year["${year}"].makes["${make}"].submodels`;
}

export function buildMakeYearModelsPath(
  make,
  year,
  pubStates = [PUB_STATES.NEW, PUB_STATES.NEW_USED, PUB_STATES.USED]
) {
  return `year["${year}"].makes["${make}"].pubStates["${pubStates.sort().join()}"].models`;
}

export function buildPubStateModelYearPath(pubStates = [PUB_STATES.NEW, PUB_STATES.NEW_USED, PUB_STATES.USED]) {
  return `modelYears.pubStates["${pubStates.sort().join()}"].years`;
}

/**
 * Gets pricing path.
 * @param {number|string} styleId
 * @returns {string}
 */
export function buildUsedTmvPricingPath(styleId) {
  return `styles.${styleId}.pricing.usedTmv.withoutOptions`;
}

export function buildUsedTmvPricingWithOptionsPath(styleId) {
  return `styles.${styleId}.pricing.usedTmv.withOptions`;
}

export function buildNewTmvPricingPath(styleId) {
  return `styles.${styleId}.pricing.newTmv.withoutOptions`;
}

export function buildOptionWithOemCodeStylePath(styleId) {
  return `styles.${styleId}.optionsWithOemCodes`;
}

export function buildMpgStylesPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].mpg.styles`;
}

export function getBaseStylePath({ make, model, year } = {}) {
  if (!(make && model && year)) {
    return null;
  }

  return `makes["${make}"].models["${model}"].years["${year}"].baseStyle`;
}

export function getUsedBaseStylePath({ make, model, year } = {}) {
  if (!(make && model && year)) {
    return null;
  }

  return `makes["${make}"].models["${model}"].years["${year}"].usedBaseStyle`;
}

// Features specs paths below
function buildStyleWithFeaturesPath({ styleId }) {
  return `stylesWithFeatures["${styleId}"]`;
}

function buildStyleFeaturesPath({ styleId }) {
  return `features.styles["${styleId}"]`;
}

function buildPartialFeaturesPath({ make, model, submodel, year }) {
  return `makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"].stylesPartialFeaturesSpecs`;
}

export const FeatureSpecsPaths = {
  buildPartialFeaturesPath,
  buildStyleWithFeaturesPath,
  buildStyleFeaturesPath,
};

export function convertV3toMakeModelSubmodelsYear(v3, makeSlug, modelSlug) {
  return {
    year: `${v3.year}`,
    id: v3.newDefaultSubmodel.id,
    pubStates: v3.publicationStates.reduce((accumulator, pubState) => {
      const accum = accumulator;
      accum[pubState] = true;
      return accum;
    }, {}),
    make: {
      $ref: `#/makes/${makeSlug}`,
    },
    model: {
      $ref: `#/makes/${makeSlug}/models/${modelSlug}`,
    },
    modelYearId: v3.id,
    modelLinkCode: v3.modelLinkCode,
    submodels: v3.submodels.map(submodel => ({
      $ref: `#/makes/${makeSlug}/models/${modelSlug}/submodels/${submodel.niceId}`,
    })),
    newDefaultStyleId: get(v3, 'newDefaultStyle.id'),
    usedDefaultStyleId: get(v3, 'usedDefaultStyle.id'),
    mostPopularStyleId: get(v3, 'mostPopularStyle.id'),
    mostPopularSubmodel: get(v3, 'mostPopularStyle.subModels[0]'),
  };
}

const EMPTY_CONSUMERS_RATING = {
  ratings: {
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
  },
  averageRating: null,
  total: null,
};
const EMPTY_FIVE_YEARS_TCO = {
  total: null,
  years: {
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
  },
};

// TODO: looks like we missed slugs in the v4 api spec
function generateSlugs(items, slugName = 'slug') {
  if (items && typeof items === 'object') {
    return reduce(
      items,
      (result, value, key) => {
        if (typeof value === 'string') {
          return {
            ...result,
            [key]: value,
          };
        }
        const withSlugs = {
          ...result,
          [key]: {
            ...value,
            [slugName]: key,
          },
        };

        if (value.submodels) {
          withSlugs[key].submodels = generateSlugs(value.submodels);
        }

        return withSlugs;
      },
      {}
    );
  }

  return items;
}

function getAverageRating(items, total) {
  const average = reduce(items, (result, curValue, index) => result + curValue * index, 0) / total;
  return parseFloat(average.toFixed(1));
}

const COLOR_TYPES = {
  EXTERIOR: 'EXTERIOR',
  INTERIOR: 'INTERIOR',
};

export function parseColors(colors) {
  return colors
    .filter(({ colorType }) => [COLOR_TYPES.EXTERIOR, COLOR_TYPES.INTERIOR].includes(colorType))
    .map(({ id, oemName, primaryRGB, oemCode, colorType, genericName }) => {
      const rgb = !isEmpty(primaryRGB) ? primaryRGB.join() : 'transparent';
      return {
        id,
        name: oemName,
        manufactureOptionCode: oemCode,
        colorType,
        genericName,
        properties: {
          rgb,
        },
      };
    });
}

function parseConsumerRatings({ results }) {
  const { total } = results;
  const ratings = pick(results, ['1', '2', '3', '4', '5']);
  const averageRating = total ? getAverageRating(ratings, total) : 0;

  return {
    ratings,
    averageRating,
    total,
  };
}

const { placeholder } = partial;
/**
 * True if style is new
 * @param {{publicationState: string}} style
 * @return {bool}
 */
const isNewStylePredicate = ({ publicationState }) => isNewState(publicationState);
/**
 * Filters styles collection leaving only new styles
 * @return {{}[]} - collection of new styles
 */
const filterOnlyNewStyles = partial(filter, placeholder, isNewStylePredicate);
/**
 * Gets only new styles ids from collection of styles
 * @return {number[]}
 */
const getNewStylesIds = flow(
  filterOnlyNewStyles,
  partial(map, placeholder, 'id')
);

function hasAllParameters(...parameters) {
  return every(parameters, parameter => parameter && parameter !== 'undefined');
}

// TODO: workaround for misbehaving style apis, remove when apis are cleaned up
export function processVehicleStyle(style) {
  if (!style) {
    return null;
  }

  const { $identity, submodels, trim, trimName, ...processedStyle } = style;

  return {
    ...processedStyle,
    submodels: isArray(submodels) ? submodels[0] : submodels,
    trimName: trimName || trim,
  };
}

/**
 * Returns: {latest NEW, non-Preprod} -> {Preprod if latest non-Preprod is USED} -> {latest USED, non-Preprod}
 */
function filterLatestNonPreprod(vehicles) {
  if (!vehicles || !vehicles.length) {
    return null;
  }
  const preprod = vehicles.findIndex(vehicle => vehicle.pubStates.PRE_PROD);
  const nonPreprod = vehicles.findIndex(vehicle => !vehicle.pubStates.PRE_PROD);

  return vehicles[nonPreprod] && !vehicles[nonPreprod].pubStates.USED
    ? vehicles[nonPreprod] || vehicles[preprod]
    : vehicles[preprod] || vehicles[nonPreprod];
}

/**
 * Builds refs in model submodels
 * @param {object} models
 * @param {string} makeSlug
 * @returns {object}
 */
function buildSubmodelsRefs(models, makeSlug) {
  return reduce(
    models,
    (submodelMap, model) => {
      const { submodels } = model;
      const submodelRefs = reduce(
        submodels,
        (refs, submodel) => ({
          ...refs,
          [`${model.slug}_${submodel.slug}`]: {
            $ref: `#/makes/${makeSlug}/models/${model.slug}/submodels/${submodel.slug}`,
          },
        }),
        {}
      );

      return {
        ...submodelMap,
        ...submodelRefs,
      };
    },
    {}
  );
}

const yearsToFilter = earliestYear => {
  const endYear = new Date().getFullYear();
  return Array(endYear - +earliestYear + 1)
    .fill(+earliestYear)
    .map((year, index) => year + index)
    .join();
};

function getMakeModelsAfterEarliestYear(url, context) {
  return withMetrics(EdmundsAPI, context)
    .fetch(url)
    .then(response => response.json().then(results => results));
}

function filterMakeModelsAfterEarliestYear(object, keys) {
  if (isEmpty(keys)) {
    return {};
  }

  return keys.reduce((acc, key) => {
    acc[key] = object[key];
    return acc;
  }, {});
}

/**
 * Filters available submodels in models.
 * @param {object} models
 * @param {object} availableSubmodels
 * @returns {object}
 */
export function filterAvailableSubmodels(models, availableSubmodels) {
  return reduce(
    models,
    (acc, val, key) => {
      // get available submodel by model key
      const submodel = availableSubmodels[key];
      if (submodel) {
        // if submodel is presented then keep only available submodels in model
        acc[key] = { ...val, submodels: pick(val.submodels, Object.entries(submodel).map(([_key]) => _key)) };
      }
      return acc;
    },
    {}
  );
}

export function buildAllTmvBandsUrl({ styleId, zipCode, mileage, colorId, optionIds }) {
  if (!zipCode || !styleId) {
    return null;
  }
  return formatAllTmvBandsUrl({ styleId, zipCode, mileage, colorId, optionIds });
}

export function buildNewTmvUrl({ styleId, zipCode }) {
  return `/newtmv/v3/calculate?styleid=${styleId}&zip=${zipCode}&typical=false`;
}

export function buildNewTmvWithOptionsUrl({ styleId, zipCode }) {
  return `/newtmv/v3/calculate?styleid=${styleId}&zip=${zipCode}&typical=true`;
}

function resolveStylesFeatures(url, context) {
  return withMetrics(EdmundsAPI, context)
    .fetchJson(url)
    .then(result => {
      if (result && result.results && result.results.forEach) {
        const promises = [];

        result.results.forEach(styleFeatures => {
          if (get(styleFeatures, 'id')) {
            promises.push(
              context.updateValue(FeatureSpecsPaths.buildStyleFeaturesPath({ styleId: styleFeatures.id }), {
                ...styleFeatures,
                $identity: undefined,
              })
            );
          }
        });

        return Promise.all(promises).then(() =>
          result.results
            .filter(styleFeatures => get(styleFeatures, 'id'))
            .map(styleFeatures => ({ $ref: `#/features/styles/${styleFeatures.id}` }))
        );
      }

      return [];
    });
}

function resolveStylesOptionsWithOemCodes({ style, optionAndColorViews }, context, VehicleModel) {
  return withMetrics(EdmundsGraphQLFederation, context)
    .query(
      gql`
        query($styleId: String!, $optionAndColorViews: [OptionAndColorView]) {
          style(styleId: $styleId) {
            id
            name
            numberOfDoors
            vehicleStyle
            partnerMapping {
              chromeIds
            }
            options(optionAndColorViews: $optionAndColorViews) {
              colors {
                id
                colorType
                oemName
                oemCode
                primaryRGB
                genericName
              }
            }
            driveTrain
            engine {
              size
              compressorType
              cylinders
              name
            }
            transmission {
              transmissionType
            }
            vehicleFeatures {
              id
              description
              price {
                usedTmvRetail
                usedTradeIn
                usedPrivateParty
              }
              options {
                id
                oemCode
                oemName
                price {
                  baseMSRP
                }
                ... on IndividualOption {
                  engine {
                    size
                    cylinders
                    name
                  }
                  transmission {
                    transmissionType
                  }
                }
              }
            }
          }
        }
      `,
      {
        styleId: style,
        optionAndColorViews,
      }
    )
    .then(({ style: styleWithOptions }) => {
      const {
        vehicleFeatures,
        engine,
        transmission,
        driveTrain,
        options: colorOptions,
        partnerMapping,
      } = styleWithOptions;
      const transmissionType = get(transmission, 'transmissionType');
      const colors = parseColors(get(colorOptions, 'colors', []));

      const filterColorsByType = (colorsArr, colorType) =>
        colorsArr.filter(({ colorType: type }) => type === colorType);

      const exteriorColors = filterColorsByType(colors, COLOR_TYPES.EXTERIOR);
      const interiorColors = filterColorsByType(colors, COLOR_TYPES.INTERIOR);

      const transformedFeatures = (vehicleFeatures || []).map(feature => {
        const { id, description, options, price } = feature;

        const transformedOptions = (options || []).map(
          ({ oemName, oemCode, price: optionPrice, engine: optionEngine, transmission: optionTransmission }) => ({
            name: oemName,
            oemCode,
            price: optionPrice,
            engine: optionEngine,
            transmission: optionTransmission,
          })
        );
        return { id, name: description, options: transformedOptions, price };
      });

      return {
        options: flatten(transformedFeatures).sort(sortByName),
        engine,
        driveTrain,
        transmissionType,
        exteriorColors,
        interiorColors,
        responseId: uuid.v4(),
        partnerMapping,
      };
    })
    .catch(async ex => {
      const drawerCreativeIdOverride = await context.resolveValue('appraisalDrawerApiResponseCreativeId', VehicleModel);
      EventToolbox.fireTrackAction(
        API_RESPONSE_STATUS_TRACKING.generateTrackingObject({
          baseTrackingObject: API_RESPONSE_STATUS_TRACKING.SHARED_BASE_TRACKING_OBJECTS.STYLE_API_VAC,
          rawErrorStatus: get(ex, 'networkError.statusCode', ex.status),
          drawerCreativeIdOverride,
        })
      );
      return { responseId: uuid.v4() };
    });
}

/**
 * NOTE: VIN-specific API resolvers can be found in the￼vehicle-vin.js data model.
 * Platform team requested the separation to reduce bloat in vehicle.js.
 */
export const VehicleModel = createModelSegment('vehicle', [
  {
    path: 'makes',
    resolve(match, context) {
      const url = '/vehicle/v4/makes/';
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url, { timeout: 1000, cache: 'force-cache' }) // increased timeout due to large payload triggering response timeout in node-fetch
        .then(({ results }) => generateSlugs(results));
    },
  },
  {
    path: 'makesList',
    resolve(match, context) {
      return context.resolveValue('makes').then(makesHash => {
        if (makesHash) {
          return toArray(makesHash)
            .sort(sortByName)
            .map(val => ({ $ref: `#/makes/${val.slug}` }));
        }
        return [];
      });
    },
  },
  {
    path: 'filterByEarliestYear["{year}"].pubStates["{pubStates}"].makesList',
    async resolve({ year, pubStates }, context) {
      const makesUrl = `/vehicle/v3/modelYears?year=${yearsToFilter(
        year
      )}&publicationStates=${pubStates}&pagesize=all&pagenum=1&distinct=makeNiceId`;
      const makes = await getMakeModelsAfterEarliestYear(makesUrl, context);
      return context.resolveValue('makes').then(makesHash => {
        if (makesHash) {
          return toArray(filterMakeModelsAfterEarliestYear(makesHash, makes))
            .sort(sortByName)
            .map(val => ({ $ref: `#/makes/${val.slug}` }));
        }
        return [];
      });
    },
  },
  {
    path: 'makes["{slug}"].models',
    async resolve({ slug }, context) {
      return context.resolveValue(`makes["${slug}"]`).then(async make => {
        if (make) {
          const url = `/vehicle/v4/makes/${slug}/submodels/`;
          const extendedApiCache = await context.resolveValue('extendedApiCache', FeatureFlagModel);

          return withMetrics(EdmundsAPI, context)
            .fetchJson(url, { timeout: 1000, cache: extendedApiCache ? 'force-cache' : undefined }) // Added for VDP caching
            .then(({ results }) => generateSlugs(results))
            .catch(() => ({}));
        }
        return {};
      });
    },
  },
  {
    path: 'filterByEarliestYear["{year}"].pubStates["{pubStates}"].makes["{slug}"].models',
    async resolve({ year, slug, pubStates }, context) {
      const modelsUrl = `/vehicle/v3/modelYears?year=${yearsToFilter(
        year
      )}&makeNiceId=${slug}&publicationStates=${pubStates}&pagesize=all&pagenum=1&distinct=modelNiceId`;
      const models = await getMakeModelsAfterEarliestYear(modelsUrl, context);
      return context.resolveValue(`makes["${slug}"]`).then(async make => {
        if (make) {
          const url = `/vehicle/v4/makes/${slug}/submodels/`;
          const extendedApiCache = await context.resolveValue('extendedApiCache', FeatureFlagModel);

          return withMetrics(EdmundsAPI, context)
            .fetchJson(url, { timeout: 1000, cache: extendedApiCache ? 'force-cache' : undefined }) // Added for VDP caching
            .then(({ results }) => generateSlugs(filterMakeModelsAfterEarliestYear(results, models)));
        }
        return {};
      });
    },
  },
  /**
   * http://www.edmunds.com/api/vehicle/v4/makes/honda/models/
   *
   * @return VehicleEntities.MakeModel - with prices
   */
  {
    path: 'makes["{make}"].models["{model}"].prices',
    resolve({ make, model }, context) {
      return context
        .resolveValue(`makes["${make}"].models`)
        .then(() => {
          const url = `/vehicle/v4/makes/${make}/models/`;
          return withMetrics(EdmundsAPI, context).fetchJson(url);
        })
        .then(({ results }) => {
          if (results && results[model]) {
            return results[model].prices;
          }
          return {};
        });
    },
  },
  /**
   * http://qa-21-www.edmunds.com/api/vehicle/v3/styles?makeNiceId=ford&modelNiceId=escape&fields=trim
   */
  {
    path: 'makes["{make}"].models["{model}"].trims',
    resolve({ make, model }, context) {
      return context.resolveValue(`makes["${make}"].models`).then(() => {
        const url = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&fields=trim&pagesize=all&pagenum=1`;
        return withMetrics(EdmundsAPI, context)
          .fetchJson(url)
          .catch(() => ({}));
      });
    },
  },
  /**
   * TODO: we will create v4 version of trims API via TRAF-2463
   * http://qa-21-www.edmunds.com/api/vehicle/v3/styles?makeNiceId=ford&modelNiceId=escap&year=2017e&fields=trim
   */
  {
    path: 'makes["{make}"].models["{model}"].year["{year}"].trims',
    resolve({ make, model, year }, context) {
      return context.resolveValue(`makes["${make}"].models["${model}"]`).then(() => {
        const url = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&year=${year}&fields=trim&pagesize=all&pagenum=1`;
        return withMetrics(EdmundsAPI, context)
          .fetchJson(url)
          .then(({ results }) => results.map(item => item.trim))
          .catch(() => ({}));
      });
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].trims',
    resolve({ make, model, submodel }, context) {
      return context.resolveValue(`makes["${make}"].models["${model}"].submodels["${submodel}"]`).then(() => {
        const url = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&subModels.niceId=${submodel}&fields=trim&pagesize=all&pagenum=1`;
        return withMetrics(EdmundsAPI, context)
          .fetchJson(url)
          .catch(() => ({}));
      });
    },
  },
  /**
   * https://qa-21-www.edmunds.com/gateway/api/vehicle/v3/styles?makeNiceId=honda&modelNiceId=accord&year=2017&subModels.niceId=coupe&fields=trim,price&pagesize=all&pagenum=1
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].year["{year}"].trimsAndPrice',
    resolve({ make, model, year, submodel }, context) {
      return context.resolveValue(`makes["${make}"].models["${model}"].submodels["${submodel}"]`).then(() => {
        const url = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&year=${year}&subModels.niceId=${submodel}&fields=trim,price&pagesize=all&pagenum=1`;
        return withMetrics(EdmundsAPI, context)
          .fetchJson(url)
          .then(({ results }) => results)
          .catch(() => ({}));
      });
    },
  },
  /**
   * TODO: we will create v4 version of trims API via TRAF-2463
   * https://qa-21-www.edmunds.com/gateway/api/vehicle/v3/styles?makeNiceId=honda&modelNiceId=accord&year=2017&subModels.niceId=coupe&fields=trim&pagesize=all&pagenum=1
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].year["{year}"].trims',
    resolve({ make, model, year, submodel }, context) {
      return context
        .resolveValue(`makes["${make}"].models["${model}"].submodels["${submodel}"].year["${year}"].trimsAndPrice`)
        .then(results => results.map(item => item.trim))
        .catch(() => ({}));
    },
  },
  /**
   * http://www.edmunds.com/api/vehicle/v3/modelYears?makeNiceId=honda&pagesize=4&modelNiceId=accord&sortby=year:DESC&pagenum=1&fields=publicationStates,subModels.identifier,subModels.name,subModels.id,subModels.niceId,makeName,modelName,id,year,newDefaultStyle.id,usedDefaultStyle.id,newDefaultSubmodel.id,makeId,modelLinkCode
   * @returns {Array.<VehicleEntities.MakeModelSubmodelsYear>}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].latestVehicles',
    resolve({ makeSlug, modelSlug }, context) {
      return context.resolveValue(`makes["${makeSlug}"].models["${modelSlug}"]`).then(model => {
        if (model) {
          const url = `/vehicle/v3/modelYears?makeNiceId=${makeSlug}&pagesize=11&modelNiceId=${modelSlug}&sortby=year%3ADESC&pagenum=1&fields=publicationStates%2CsubModels.identifier%2CsubModels.name%2CsubModels.id%2CsubModels.niceId%2CmakeName%2CmodelName%2Cid%2Cyear%2CnewDefaultStyle.id%2CusedDefaultStyle.id%2CnewDefaultSubmodel.id%2CmakeId%2CmodelLinkCode`;
          return withMetrics(EdmundsAPI, context)
            .fetch(url)
            .then(response => response.json())
            .then(({ results }) => results.map(v3 => convertV3toMakeModelSubmodelsYear(v3, makeSlug, modelSlug)));
        }

        return [];
      });
    },
  },
  /**
   * Returns first matched vehicle based on make, model and year params.
   * http://www.edmunds.com/api/vehicle/v3/modelYears?makeNiceId=honda&pagesize=1&modelNiceId=accord&year=2020&pagenum=1&fields=publicationStates,subModels.identifier,subModels.name,subModels.id,subModels.niceId,makeName,modelName,id,year,newDefaultStyle.id,usedDefaultStyle.id,mostPopularStyle.id,mostPopularStyle.subModels,newDefaultSubmodel.id,makeId,modelLinkCode
   * @returns {Object.<VehicleEntities.MakeModelSubmodelsYear>|{}}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"]',
    async resolve({ makeSlug, modelSlug, year }, context) {
      const url = `/vehicle/v3/modelYears?makeNiceId=${makeSlug}&pagesize=1&modelNiceId=${modelSlug}&year=${year}&pagenum=1&fields=publicationStates%2CsubModels.identifier%2CsubModels.name%2CsubModels.id%2CsubModels.niceId%2CmakeName%2CmodelName%2Cid%2Cyear%2CnewDefaultStyle.id%2CusedDefaultStyle.id%2CmostPopularStyle.id%2CmostPopularStyle.subModels%2CnewDefaultSubmodel.id%2CmakeId%2CmodelLinkCode`;

      let response;
      try {
        response = await withMetrics(EdmundsAPI, context).fetchJson(url);
        const firstResult = get(response, 'results.0', {});
        response = convertV3toMakeModelSubmodelsYear(firstResult, makeSlug, modelSlug);
      } catch (ex) {
        response = {};
      }

      return response;
    },
  },
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].estimatedStyleIds',
    async resolve({ makeSlug, modelSlug, year }, context) {
      const { newDefaultStyleId, usedDefaultStyleId, mostPopularStyleId } = await context.resolveValue(
        buildNoSubmodelVehiclePath({ make: makeSlug, model: modelSlug, year })
      );
      return {
        newDefaultStyleId,
        usedDefaultStyleId,
        mostPopularStyleId,
      };
    },
  },
  /**
   * @see buildPubStateModelYearPath
   */
  {
    path: 'modelYears.pubStates["{pubStates}"].years',
    async resolve({ pubStates }, context) {
      const url = `/vehicle/v3/modelYears?publicationStates=${pubStates}&distinct=year:DESC`;
      const years = await withMetrics(EdmundsAPI, context).fetchJson(url);
      return years.map(year => ({ year }));
    },
  },
  {
    path: 'modelYears.type["{typeSlug}"]',
    async resolve({ typeSlug }, context) {
      const url = `/vehicle/v3/modelYears?publicationStates=NEW,NEW_USED&${getQueryTypeStringModelYears(
        typeSlug
      )}&fields=makeName,modelName,year,subModels&pagenum=1&pagesize=all`;
      const { results } = await withMetrics(EdmundsAPI, context).fetchJson(url);
      return results;
    },
  },
  /**
   * Notice that this path resolves to `null` for cases where there are only preprod vehicles.
   * @returns {{ ref: VehicleEntities.MakeModelSubmodelsYear }|null}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].latestNonPreprodVehicle',
    resolve({ makeSlug, modelSlug }, context) {
      return context.resolveValue(`makes["${makeSlug}"].models["${modelSlug}"].latestVehicles`).then(latestVehicles => {
        let refIndex = -1;

        if (latestVehicles && latestVehicles.length) {
          refIndex = latestVehicles.findIndex(vehicle => !vehicle.pubStates.PRE_PROD);
        }

        // return null when there are only preprod vehicles
        return refIndex > -1
          ? { ref: { $ref: `#/makes/${makeSlug}/models/${modelSlug}/latestVehicles/${refIndex}` } }
          : null;
      });
    },
  },
  /**
   * This path returns the latest MakeModelSubmodelYear vehicle with only {make} and {model} params.
   * The vehicle returned is determinted by the following order of preference, from high to low:
   *
   * Returns: {latest NEW, non-Preprod} -> {Preprod if latest non-Preprod is USED} -> {latest USED, non-Preprod}
   *
   * It is currently being used to traffic '/make/model' to the core page.
   * @returns {VehicleEntities.MakeModelSubmodelYear|null}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].activeVehicle',
    resolve({ makeSlug: make, modelSlug: model }, context) {
      return context.resolveValue(`makes["${make}"].models["${model}"].latestVehicles`).then(vehicles => {
        const vehicle = filterLatestNonPreprod(vehicles);

        return vehicle
          ? context
              .resolveValue(`makes["${make}"].models["${model}"].defaultSubmodel.years["${vehicle.year}"]`)
              .then(mmsy => ({
                $ref: `#/makes/${make}/models/${model}/submodels/${mmsy.submodels.slug}/years/${mmsy.year}`,
              }))
          : null;
      });
    },
  },
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].activeVehicle.withReview',
    async resolve({ makeSlug, modelSlug }, context) {
      const url = await getDefaultYearUrl({ context, makeSlug, modelSlug });

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(years => {
          if (!years) {
            return null;
          }

          const year = years.results.year || years.results.defaultYear;

          return context
            .resolveValue(`makes["${makeSlug}"].models["${modelSlug}"].defaultSubmodel.years["${year}"]`)
            .then(mmsy => ({
              $ref: `#/makes/${makeSlug}/models/${modelSlug}/submodels/${mmsy.submodels.slug}/years/${mmsy.year}`,
            }));
        })
        .catch(() => null);
    },
  },
  /**
   * Most popular submodel - Also default submodel
   * https://www.edmunds.com/gateway/api/vehicle/v3/submodels/?pagesize=1&sortby=makeShare%3ADESC&pagenum=1&makeNiceId=ford&modelNiceId=f150&year=2017&fields=id%2Cname%2Ctrim.name%2CsubModels.name
   * Returns:
   * {
   *   id: PropTypes.number,
   *   name: PropTypes.string,
   *   niceId: PropTypes.string,
   *   } || {}
   */
  {
    path: 'makes["{make}"].models["{model}"].defaultSubmodel.latestNonPreprodVehicle',
    resolve({ make, model }, context) {
      let latestYear;
      return context
        .resolveValue(`makes["${make}"].models["${model}"].latestNonPreprodVehicle`, VehicleModel)
        .then(latestVehicle => {
          if (latestVehicle && latestVehicle.ref && latestVehicle.ref.year) {
            latestYear = latestVehicle.ref.year;
            const url = `/vehicle/v3/modelYears?makeNiceId=${make}&pagesize=1&modelNiceId=${model}&year=${latestYear}&pagenum=1&fields=publicationStates,subModels.identifier,subModels.name,subModels.id,subModels.niceId,makeName,modelName,id,year,newDefaultStyle.id,usedDefaultStyle.id,newDefaultSubmodel.id,makeId,modelLinkCode`;
            return withMetrics(EdmundsAPI, context).fetchJson(url);
          }
          return {};
        })
        .then(result => {
          const defaultId = get(result, 'results[0].newDefaultSubmodel.id');
          if (!defaultId) {
            return {};
          }
          const defaultSubmodel = get(result, 'results[0].submodels', []).find(item => item.id === defaultId);
          return defaultSubmodel || {};
        });
    },
  },
  /**
   * @see buildStylesPath
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].submodels["{submodelSlug}"].styles.years["{year}"]',
    resolve({ makeSlug, modelSlug, submodelSlug, year }, context) {
      const submodelPath = `makes["${makeSlug}"].models["${modelSlug}"].submodels["${submodelSlug}"]`;
      return context.resolveValue(submodelPath).then(() => {
        const url = `/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/submodels/${submodelSlug}/years/${year}/styles/?fields=id,name,niceName,niceId,trim(name),price.baseMSRP`;

        return (
          withMetrics(EdmundsAPI, context)
            .fetchJson(url)
            .then(({ results }) => filterFleetTrims(sortBy(results, 'price.baseMSRP'), context))
            // TODO: workaround for missing/incorrect fields in mmy styles api, remove after api is cleaned up
            .then(styles =>
              styles.filter(({ publicationState }) => !isDisabledState(publicationState)).map(processVehicleStyle)
            )
        );
      });
    },
  },
  /**
   * @see buildStylesPath
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].styles',
    resolve({ makeSlug, modelSlug, year }, context) {
      const url = `/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/years/${year}/styles/`;

      return (
        withMetrics(EdmundsAPI, context)
          .fetchJson(url)
          .then(({ results }) => filterFleetTrims(results, context))
          // TODO: workaround for missing/incorrect fields in mmy styles api, remove after api is cleaned up
          .then(styles =>
            styles.filter(({ publicationState }) => !isDisabledState(publicationState)).map(processVehicleStyle)
          )
      );
    },
  },
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].submodels["{submodelSlug}"].years',
    resolve({ makeSlug, modelSlug, submodelSlug }, context) {
      const submodelPath = `makes["${makeSlug}"].models["${modelSlug}"].submodels["${submodelSlug}"]`;
      return context.resolveValue(submodelPath).then(submodel => {
        if (submodel) {
          const url = `/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/submodels/${submodelSlug}/years/`;
          return withMetrics(EdmundsAPI, context)
            .fetch(url)
            .then(response => response.json().then(({ results }) => generateSlugs(results, 'year')));
        }

        return {};
      });
    },
  },
  {
    path: 'makes["{slug}"].submodels',
    resolve({ slug }, context) {
      return context.resolveValue(`makes["${slug}"].models`).then(models => buildSubmodelsRefs(models, slug));
    },
  },
  {
    path: 'makes["{makeSlug}"].latestSubmodels',
    async resolve({ makeSlug }, context) {
      const url = `/vehicle/v3/submodels?makeNiceId=${makeSlug}&year=${getLatestYears()}&pageSize=550&pageNum=1`;

      const [{ results }, models] = await Promise.all([
        withMetrics(EdmundsAPI, context).fetchJson(url),
        context.resolveValue(`makes["${makeSlug}"].models`),
      ]);

      const availableSubmodels = results.reduce((acc, { modelNiceId, niceId }) => {
        set(acc, `${modelNiceId}.${niceId}`, true);
        return acc;
      }, {});
      const filteredModelsWithSubmodels = filterAvailableSubmodels(models, availableSubmodels);

      return buildSubmodelsRefs(filteredModelsWithSubmodels, makeSlug);
    },
  },

  /**
   * @see buildMakeYearSubmodelsPath
   */
  {
    path: 'year["{year}"].makes["{slug}"].submodels',
    resolve({ slug, year }, context) {
      const url = `/vehicle/v3/submodels?makeNiceId=${slug}&year=${year}&fields=name,niceId,modelNiceId,publicationStates&sortby=name&pagesize=1000&pagenum=1`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ results }) => results)
        .catch(ex => {
          if (ex.status === HTTP_NOT_FOUND) {
            // this is expected for invalid vehicles
            return null;
          }
          throw ex;
        });
    },
  },
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].submodelsByName["{submodelName}"].years["{year}"]',
    resolve({ makeSlug, modelSlug, submodelName, year }, context) {
      return context
        .resolveValue(`makes["${makeSlug}"].models`)
        .then(models => {
          const submodelSlug = findKey(models[modelSlug].submodels, { name: submodelName });
          if (submodelSlug) {
            const url = `/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/submodels/${submodelSlug}/years/`;
            return withMetrics(EdmundsAPI, context)
              .fetch(url)
              .then(response => response.json().then(({ results }) => generateSlugs(results, 'year')))
              .then(submodelYears => submodelYears[year]);
          }

          throw new Error('Wrong submodel passed. Please pass full submodel name in url, ex. Accord Sedan');
        })
        .catch(ex => {
          if (ex.status === HTTP_NOT_FOUND) {
            return null; // this is expected for invalid vehicles
          }
          throw ex;
        });
    },
  },
  /**
   * The generic features is a list of possible features a car can have such as 'Heated Seats'. This is nice because
   * there is variance between manufacturers on what a feature is called.
   */
  {
    path: 'genericFeatures',
    resolve(match, context) {
      return withMetrics(EdmundsAPI, context).fetchJson(GENERIC_FEATURES_PATH);
    },
  },
  {
    /**
     * TODO Migrate to v4 vehicle api
     * TODO Return a $ref to the submodel once the bug is fixed
     * Most popular submodel - Also default submodel
     * https://www.edmunds.com/gateway/api/vehicle/v3/submodels/?pagesize=1&sortby=makeShare%3ADESC&pagenum=1&makeNiceId=ford&modelNiceId=f150&year=2017&fields=id%2Cname%2Ctrim.name%2CsubModels.name
     * makes["honda"].models["accord"].defaultSubmodel.years["2017"]
     */
    path: 'makes["{make}"].models["{model}"].defaultSubmodel.years["{year}"]',
    async resolve({ make, model, year }, context) {
      const extendedApiCache = await context.resolveValue('extendedApiCache', FeatureFlagModel);
      const url = `/vehicle/v3/modelYears?makeNiceId=${make}&pagesize=1&modelNiceId=${model}&year=${year}&pagenum=1&fields=publicationStates,subModels.identifier,subModels.name,subModels.id,subModels.niceId,makeName,modelName,id,year,newDefaultStyle.id,usedDefaultStyle.id,newDefaultSubmodel.id,makeId,modelLinkCode`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url, { timeout: 1000, cache: extendedApiCache ? 'force-cache' : undefined }) // Added for VDP Cache
        .then(result => {
          const defaultId = get(result, 'results[0].newDefaultSubmodel.id');
          if (!defaultId) {
            return null;
          }

          const defaultSubmodel = get(result, 'results[0].submodels', []).find(item => item.id === defaultId);

          if (!defaultSubmodel) {
            return null;
          }

          const defaultSubmodelNiceId = defaultSubmodel.niceId;

          return context
            .resolveValue(`makes["${make}"].models["${model}"].submodels["${defaultSubmodelNiceId}"].years["${year}"]`)
            .then(() => ({
              $ref: `#/makes/${make}/models/${model}/submodels/${defaultSubmodelNiceId}/years/${year}`,
            }));
        });
    },
  },
  /**
   * @returns VehicleEntities.PopularStyles
   *
   * path: buildPopularStylesPath
   * api: https://qa-21-www.edmunds.com/api/vehicle/v4/makes/mercedes-benz/models/s-class/years/2017/styles
   */
  {
    path: 'makes["{make}"].models["{model}"].defaultSubmodel.years["{year}"].styles',
    resolve({ make, model, year }, context) {
      return context
        .resolveValue(buildVehiclePath({ make, model, year }))
        .then(() =>
          withMetrics(EdmundsAPI, context)
            .fetchJson(`/vehicle/v4/makes/${make}/models/${model}/years/${year}/styles`)
            .then(async ({ results }) => (await filterFleetTrims(results, context)) || null)
        )
        .catch(ex => {
          if (ex.status === HTTP_NOT_FOUND) {
            // this is expected for invalid vehicles
            return null;
          }
          throw ex;
        });
    },
  },
  /**
   * @returns VehicleEntities.PopularStyles
   *
   * path: buildPopularStylesPath
   * api: https://qa-21-www.edmunds.com/api/vehicle/v4/makes/mercedes-benz/models/s-class/submodels/amg-s-65/years/2017/styles
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].styles',
    resolve({ make, model, year, submodel }, context) {
      return context
        .resolveValue(`makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"]`)
        .then(() =>
          withMetrics(EdmundsAPI, context)
            .fetchJson(`/vehicle/v4/makes/${make}/models/${model}/submodels/${submodel}/years/${year}/styles`)
            .then(async ({ results }) => (await filterFleetTrims(results, context)) || null)
        )
        .catch(ex => {
          if (ex.status === HTTP_NOT_FOUND) {
            return null; // this is expected for invalid vehicles
          }
          throw ex;
        });
    },
  },
  /**
   * Notice this returns an object of submodels with years inside the submodel object.
   * If you need a specific year, you should be using the
   * makes["{make}"].models["{model}"].defaultSubmodel.years["{year}"] path.
   *
   * example api: http://www.edmunds.com/api/vehicle/v4/makes/honda/models/accord/years
   *
   * @return PropTypes.shape({
   *   pubStates: PubStates,
   *   make: Make,
   *   model: MakeModel,
   *   name: PropTypes.string,
   *   years: PropTypes.objectOf(MakeModelSubmodelYear),
   * });
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].submodel.years',
    resolve({ makeSlug, modelSlug }, context) {
      return context.resolveValue(`makes["${makeSlug}"].models["${modelSlug}"]`).then(model => {
        if (model) {
          return withMetrics(EdmundsAPI, context)
            .fetchJson(`/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/years`)
            .then(({ results }) => results);
        }
        return {};
      });
    },
  },
  /**
   * Returns years object for selected make and model.
   * example api: http://www.edmunds.com/api/vehicle/v4/makes/honda/models/accord/years
   *
   * @return PropTypes.objectOf(MakeModelYears)
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years',
    resolve({ makeSlug, modelSlug }, context) {
      return context.resolveValue(`makes["${makeSlug}"].models["${modelSlug}"]`).then(model => {
        if (model) {
          return withMetrics(EdmundsAPI, context)
            .fetchJson(`/vehicle/v4/makes/${makeSlug}/models/${modelSlug}/years`)
            .then(({ results: submodels }) => {
              const years = {};
              forEach(submodels, submodel => {
                forEach(submodel.years, (yearData, year) => {
                  years[year] = {
                    ...yearData,
                    year,
                  };
                });
              });
              return years;
            });
        }
        return {};
      });
    },
  },
  /**
   * @see buildMakeYearsPath
   * Example: https://qa-21-www.edmunds.com/api/vehicle/v3/modelYears?makeNiceId=honda&publicationStates=USED,NEW_USED,NEW&distinct=year
   */
  {
    path: 'makes["{makeSlug}"].years',
    resolve({ makeSlug }, context) {
      return withMetrics(EdmundsAPI, context).fetchJson(
        `/vehicle/v3/modelYears?makeNiceId=${makeSlug}&publicationStates=USED,NEW_USED,NEW&distinct=year`
      );
    },
  },
  /**
   * Notice this returns an array of submodels with for current make model year parameters.
   * example api: http://www.edmunds.com/api/vehicle/v4/makes/honda/models/accord/years/
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].submodelsByYear["{year}"]',
    resolve({ makeSlug, modelSlug, year }, context) {
      return context.resolveValue(`makes["${makeSlug}"].models["${modelSlug}"].submodel.years`).then(results =>
        reduce(
          results,
          (submodelList, submodelYears) => {
            const submodel = submodelYears.years[year] && submodelYears.years[year].submodels;
            return submodel
              ? [
                  {
                    $ref: `#/makes/${submodel.make.slug}/models/${submodel.model.slug}/submodels/${submodel.slug}`,
                  },
                  ...submodelList,
                ]
              : submodelList;
          },
          []
        )
      );
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].submodelsGql',
    resolve({ make, model, year }, context) {
      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($makeSlug: String!, $modelSlug: String!, $year: Int!) {
              submodelsOfMMY: modelYears(makeSlug: $makeSlug, modelSlug: $modelSlug, year: $year) {
                submodels(filter: { ruleType: "body" }) {
                  name
                  slug
                  id
                  trims {
                    name
                    slug
                    styleList {
                      styles {
                        id
                        name
                        slug
                      }
                    }
                  }
                }
              }
            }
          `,
          {
            makeSlug: make,
            modelSlug: model,
            year: parseInt(year, 10),
          }
        )
        .then(({ submodelsOfMMY }) => submodelsOfMMY[0].submodels);
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].trims["{trim}"]',
    resolve({ make, model, year, trim }, context) {
      return context.resolveValue(`makes["${make}"].models["${model}"].submodels`).then(() => {
        const url = `/vehicle/v4/makes/${make}/models/${model}/years/${year}/trims/${trim}/styles/`;
        return (
          withMetrics(EdmundsAPI, context)
            .fetchJson(url)
            .then(({ results }) => results)
            .then(({ styles, trimSlug, trimName }) => ({
              styles: sortBy(styles, 'price.baseMSRP'),
              trimName,
              trimSlug,
            }))
            // We need to catch this API cause it throws 404 Not Found if trim data for current make model year trim was
            // not found. Later we need to handle 'no styles for trim' case.
            .catch(ex => {
              if (ex.status === HTTP_NOT_FOUND) {
                return {};
              }
              throw ex; // This is most likely a timeout or broken data - throw up
            })
        );
      });
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].trims["{trim}"].nationalPrices',
    resolve({ make, model, year, trim }, context) {
      return context
        .resolveValue(`makes["${make}"].models["${model}"].years["${year}"].trims["${trim}"]`)
        .then(({ styles }) => {
          const stylesIds = getNewStylesIds(styles);
          if (stylesIds.length) {
            return context.resolveValue('location', VisitorModel).then(({ stateCode }) => {
              const url = `/newtmv/v3/nationalprices?styleid=${stylesIds}&statecode=${stateCode}`;
              return withMetrics(EdmundsAPI, context)
                .fetchJson(url)
                .then(({ results }) => results);
            });
          }
          return {};
        });
    },
  },
  {
    path: 'year["{year}"].pubStates["{pubStates}"].makesList',
    resolve({ year, pubStates }, context) {
      const url = `/vehicle/v3/modelYears?year=${year}&publicationStates=${pubStates}&pagesize=all&pagenum=1&distinct=makeName`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(
          response =>
            response &&
            response
              .map(make => ({
                name: make,
                niceName: makeNiceName(make),
              }))
              .sort(sortByName)
        )
        .catch(ex => {
          if (ex.status === HTTP_NOT_FOUND) {
            return {};
          }
          throw ex; // This is most likely a timeout or broken data - throw up
        });
    },
  },
  {
    path: 'year["{year}"].makes["{makeSlug}"].pubStates["{pubStates}"].models',
    resolve({ year, makeSlug, pubStates }, context) {
      const url = `/vehicle/v3/modelYears?year=${year}&makeNiceId=${makeSlug}&publicationStates=${pubStates}&pagesize=all&pagenum=1&fields=modelName,modelNiceId&sortby=modelName`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(
          response =>
            response &&
            response.results &&
            response.results.map(model => ({
              name: model.modelName,
              niceId: model.modelNiceId,
            }))
        )
        .catch(ex => {
          if (ex.status === HTTP_NOT_FOUND) {
            return {};
          }
          throw ex; // This is most likely a timeout or broken data - throw up
        });
    },
  },
  {
    path: 'features.{style}',
    resolve({ style }, context) {
      const url = `/vehiclefeatures/v3/comparable-features/?styleid=${style}`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(response => response.results[style]);
    },
  },
  {
    path: 'paramFeatures.{style}',
    resolve({ style }, context) {
      return context.resolveValue(`features.${style}`).then(fValues => {
        const EMPTY_FEATURE_VALUE = 'N/A';

        return featuresMap.reduce(
          (result, { camelName, name, values }) =>
            Object.assign(result, {
              [name]: values.reduce((feature, value) => {
                const isValueObject = typeof value === 'object';
                const valuePath = value.path || `${camelName}.${value.camelName}`;
                let featureValue = get(fValues, valuePath, EMPTY_FEATURE_VALUE);
                if (typeof featureValue === 'string') {
                  featureValue = featureValue.replace(/\bn\/a\b/g, EMPTY_FEATURE_VALUE);
                }

                if (isValueObject && value.formatter) {
                  featureValue = value.formatter(featureValue);
                }
                const featureKey = isValueObject ? value.name : value;
                return Object.assign(feature, {
                  [featureKey]: featureValue || EMPTY_FEATURE_VALUE,
                });
              }, {}),
            }),
          {}
        );
      });
    },
  },
  {
    path: 'colorFeatures.{style}',
    resolve({ style }, context) {
      return context.resolveValue(`features.${style}`).then(({ colorFeatures = [] } = {}) =>
        colorFeatures.reduce((result, { title, type, primaryRgbValue }) => {
          const colors = primaryRgbValue.split(',');

          result[type] = result[type] || []; // eslint-disable-line no-param-reassign

          result[type].push({
            type,
            title,
            color: {
              r: Number(colors[0]),
              g: Number(colors[1]),
              b: Number(colors[2]),
            },
          });

          return result;
        }, {})
      );
    },
  },
  /**
   * This filter is used to collect last vehicle appraised style that used to get estimated appraisal values
   */
  {
    path: 'estimatedAppraisalStyle',
  },
  {
    path: 'estimatedAppraisalMileage',
  },
  {
    path: 'estimatedAppraisalVin',
  },
  /**
   * Vehicle style information including submodels information connected via refs
   * @see VehicleEntities.Style
   */
  {
    path: 'styles.{style}',
    resolve({ style }, context) {
      const url = `/vehicle/v4/styles/?id=${style}`;
      return (
        withMetrics(EdmundsAPI, context)
          .fetchJson(url)
          .then(response => get(response, 'results[0]'))
          .then(
            styleData =>
              styleData &&
              context
                .resolveValue(`makes["${styleData.makeSlug}"].models["${styleData.modelSlug}"].submodels`)
                .then(() => styleData)
          )
          // TODO: workaround for self-ref in api. Remove this after api is cleaned up.
          .then(processVehicleStyle)
          // We need to catch this API cause it throws 404 Not Found if style data for current style id was
          // not found. Later we need to handle 'no style for page' case on page level if we need.
          .catch(ex => {
            if (ex.status === HTTP_NOT_FOUND) {
              return {};
            }
            throw ex; // This is most likely a timeout or broken data - throw up
          })
      );
    },
  },
  {
    path: 'styles.{styleId}.pricing',
    resolve({ styleId }, context) {
      return context.resolveValue(`styles.${styleId}`).then(style => {
        if (style) {
          const url = `/vehicle/v3/styles?id=${style.id}&fields=price`;
          return withMetrics(EdmundsAPI, context)
            .fetch(url)
            .then(response => response.json())
            .then(response => response.results[0].price);
        }
        return {};
      });
    },
  },
  /**
   *  Returns all styles by MMY with MPG data
   *  E.g. https://qa-21-www.edmunds.com/gateway/api/vehicle/v3/styles?makeNiceId=honda&modelNiceId=civic&year=2017&fields=engineFuelType,id,attributeGroups.SPECIFICATIONS.properties.EPA_CITY_MPG,attributeGroups.SPECIFICATIONS.properties.EPA_HIGHWAY_MPG,attributeGroups.SPECIFICATIONS.properties.EPA_COMBINED_MPG,name,subModels.name&pagesize=100&pagenum=1
   *
   *  @see buildMpgStylesPath
   *  @return {Array<VehicleEntities.StyleMpg>}
   */
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].mpg.styles',
    resolve({ make, model, year }, context) {
      const url = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&year=${year}&fields=engineFuelType,id,trim.name,attributeGroups.SPECIFICATIONS.properties.EPA_CITY_MPG,attributeGroups.SPECIFICATIONS.properties.EPA_HIGHWAY_MPG,attributeGroups.SPECIFICATIONS.properties.EPA_COMBINED_MPG,name,subModels.name&pagesize=100&pagenum=1`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(
          ({ results }) =>
            results &&
            results.map(
              ({
                id,
                engineFuelType,
                name,
                subModels,
                attributeGroups: {
                  SPECIFICATIONS: {
                    properties: { EPA_COMBINED_MPG: combined, EPA_CITY_MPG: city, EPA_HIGHWAY_MPG: highway },
                  },
                },
                trim,
              }) => ({
                id,
                engineFuelType,
                name,
                trimName: get(trim, 'name'),
                submodelName: get(subModels, '[0].name'),
                mpgData: { combined, city, highway },
              })
            )
        );
    },
  },
  /**
   * @see buildMmysMsrpPricesPath
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].msrpPriceRanges',
    resolve({ make, model, year, submodel }, context) {
      return context
        .resolveValue(`makes["${make}"].models["${model}"].submodels["${submodel}"].years["${year}"]`)
        .then(() => {
          const pageSize = 20;
          const url = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&year=${year}&subModels.niceId=${submodel}&sortby=price.baseMSRP:DESC&fields=price.baseMSRP,attributeGroups.STYLE_INFO.attributes.STYLE_END_DATE&pagesize=${pageSize}&pagenum=1`;
          return withMetrics(EdmundsAPI, context)
            .fetchJson(url)
            .then(({ results, totalPages }) => ({ results: filterDiscontinuedStyles(results), totalPages }))
            .then(async ({ results, totalPages }) => {
              const msrpRange = []; // return empty array if no base prices exist (like for preprod vehicles)
              if (get(results, 'length')) {
                const maxPrice = get(results.find(style => !!get(style, 'price.baseMSRP')), 'price.baseMSRP');
                if (maxPrice) {
                  msrpRange.push(maxPrice);
                }

                let lastPageMinPrice;
                let pageNum = totalPages;

                // Find first not discontinued style from the end and take its price
                while (!lastPageMinPrice && pageNum > 1) {
                  const lastPageUrl = `/vehicle/v3/styles?makeNiceId=${make}&modelNiceId=${model}&year=${year}&subModels.niceId=${submodel}&sortby=price.baseMSRP:DESC&fields=price.baseMSRP,attributeGroups.STYLE_INFO.attributes.STYLE_END_DATE&pagesize=${pageSize}&pagenum=${pageNum}`;
                  // eslint-disable-next-line no-await-in-loop
                  const lastPageResults = await withMetrics(EdmundsAPI, context)
                    .fetchJson(lastPageUrl)
                    .then(({ results: lastResults }) => filterDiscontinuedStyles(lastResults));

                  lastPageMinPrice = get(
                    findLast(lastPageResults, style => !!get(style, 'price.baseMSRP')),
                    'price.baseMSRP'
                  );

                  pageNum -= 1;
                }

                if (!lastPageMinPrice && pageNum === 1) {
                  lastPageMinPrice = get(results, `[${results.length - 1}].price.baseMSRP`);
                }

                if (lastPageMinPrice) {
                  msrpRange.push(lastPageMinPrice);
                }
              }
              return msrpRange;
            });
        });
    },
  },
  {
    path: 'styles.{styleId}.photo',
    resolve({ styleId }, context) {
      const url = `/media/v2/styles/${styleId}/photos/?shottype=FQ&category=exterior&provider=OEM&width=300&pagesize=1&pagenum=1`;
      const stylePath = `styles.${styleId}`;

      return Promise.all([context.resolveValue(stylePath), withMetrics(EdmundsAPI, context).fetch(url)])
        .then(
          results => results[1].json() // get response from fetchJson
        )
        .then(({ photos }) =>
          // TODO: TMT-462 - remove hardcode when questions about how to load image will be resolved.
          // Current API return nested objects, arrays so we should take photos[0] and then source[0].
          get(photos, '[0].sources[0].link.href', '')
        );
    },
  },
  {
    path: 'styles.{style}.ratings.count',
    resolve({ style }, context) {
      const stylePath = `styles.${style}`;
      return context
        .resolveValue(stylePath)
        .then(styleData => {
          const { makeSlug, modelSlug, year } = styleData;
          if (!hasAllParameters(makeSlug, modelSlug, year)) {
            throw new Error('Query has missing parameter');
          }

          const url = `/vehiclereviews/v3/${makeSlug}/${modelSlug}/${year}/ratings/count/?fmt=graph`;

          return withMetrics(EdmundsAPI, context).fetch(url);
        })
        .then(response => response.json())
        .then(parseConsumerRatings)
        .catch(() => EMPTY_CONSUMERS_RATING);
    },
  },
  /**
   * @see buildNewTmvPricingPath
   */
  {
    path: 'styles.{styleId}.pricing.newTmv.withoutOptions',
    resolve({ styleId }, context) {
      return context
        .resolveValue(`styles.${styleId}`)
        .then(() => context.resolveValue('location.zipCode', VisitorModel))
        .then(zipCode =>
          withMetrics(EdmundsAPI, context).fetchJson(
            `/newtmv/v3/calculate?styleid=${styleId}&zip=${zipCode}&typical=true`
          )
        )
        .then(({ results: { tmv } }) => tmv);
    },
  },
  /**
   * Example: https://qa-21-www.edmunds.com/api/v2/usedtmv/getalltmvbands?styleid=401640309&zipcode=99780&typical=false&mileage=180000&view=full&priceband=false
   * @returns StylePricing.usedTmv.withoutOptions
   */
  {
    path: 'styles.{styleId}.pricing.usedTmv.withoutOptions',
    async resolve({ styleId }, context) {
      let response;

      try {
        const [mileage, zipCode] = await Promise.all([
          context.resolveValue('estimatedAppraisalMileage'),
          context.resolveValue('location.zipCode', VisitorModel),
        ]);
        const url = buildAllTmvBandsUrl({ styleId, zipCode, mileage });
        response = await withMetrics(EdmundsAPI, context).fetchJson(url);
      } catch (ex) {
        response = {
          tmvconditions: {},
        };
      }

      return response.tmvconditions;
    },
  },
  {
    path: 'allTmvParams',
  },
  {
    path: 'appraisalDrawerApiResponseCreativeId',
  },
  {
    path: 'styles.{styleId}.pricing.usedTmv.withOptions',
    async resolve(params, context) {
      let response;
      const allTmvParams = (await context.resolveValue('allTmvParams')) || {};
      const tmvApiUrl = buildAllTmvBandsUrl(allTmvParams);
      if (!tmvApiUrl) return context.abort();

      try {
        response = await withMetrics(EdmundsAPI, context).fetchJson(tmvApiUrl, { showAPIError: true });
      } catch (ex) {
        const drawerCreativeIdOverride = await context.resolveValue(
          'appraisalDrawerApiResponseCreativeId',
          VehicleModel
        );
        EventToolbox.fireTrackAction(
          API_RESPONSE_STATUS_TRACKING.generateTrackingObject({
            baseTrackingObject: API_RESPONSE_STATUS_TRACKING.SHARED_BASE_TRACKING_OBJECTS.TMV_API_EPO,
            drawerCreativeIdOverride,
            rawErrorStatus: ex.status,
          })
        );
        response = {
          tmvconditions: {},
          hasError: true,
        };
        logger('error', `Used TMV API fetch error - ${ex.message || ex}`);
      }

      return { ...response, responseId: uuid.v4() };
    },
  },
  {
    path: 'styles.{styleId}.pricing.estimatedPricing',
    resolve({ styleId }, context) {
      return context
        .resolveValue(`styles.${styleId}`)
        .then(() => context.resolveValue('location.zipCode', VisitorModel))
        .then(zip =>
          withMetrics(EdmundsAPI, context).fetchJson(`/inventory/v5/estimated-savings/${styleId}?zip=${zip}`)
        )
        .then(({ response }) => response);
    },
  },
  /**
   * @see buildVehicleStyleInsurancePricesPath
   */
  {
    path: 'styles.{style}.insurancePrices',
    resolve({ style }, context) {
      return context
        .resolveValue(buildVehicleStylePath({ styleId: style }))
        .then(() => context.resolveValue('location', VisitorModel))
        .then(location => {
          const { stateCode } = location;
          const url = `/tco/v3/insurance?styleid=${style}&statecode=${stateCode}`;
          return withMetrics(EdmundsAPI, context).fetchJson(url);
        })
        .then(({ results }) => results);
    },
  },
  {
    path: 'styles.{style}.fiveyearstco',
    resolve({ style }, context) {
      return context
        .resolveValue(`styles.${style}`)
        .then(() => context.resolveValue('location', VisitorModel))
        .then(location => {
          const { zipCode } = location;
          const url = `/tco/v3/styles/${style}/zips/${zipCode}/fiveyearstco`;
          return withMetrics(EdmundsAPI, context).fetchJson(url);
        })
        .then(({ results }) => results)
        .catch(() => EMPTY_FIVE_YEARS_TCO);
    },
  },
  {
    path: 'styles.{style}.optionsWithOemCodes',
    async resolve({ style }, context) {
      return resolveStylesOptionsWithOemCodes(
        { style, optionAndColorViews: ['CONSUMER', 'CONSUMER_FLEET'] },
        context,
        VehicleModel
      );
    },
  },
  /**
   * @return PropTypes.arrayOf(FeatureSpecsEntities.FeatureStyleEntity)
   *
   * path: buildMmyFeaturesPath
   * api: https://qa-21-www.edmunds.com/api/vehicle/v4/makes/honda/models/accord/years/2018/styles/features-specs?pageNum=1&pageSize=3
   */
  {
    path: 'features.makes["{make}"].models["{model}"].defaultSubmodel.years["{year}"].styles',
    resolve({ make, model, year }, context) {
      return resolveStylesFeatures(
        `/vehicle/v4/makes/${make}/models/${model}/years/${year}/styles/features-specs?pageNum=1&pageSize=3`,
        context
      );
    },
  },
  /**
   * @return PropTypes.arrayOf(FeatureSpecsEntities.FeatureStyleEntity)
   *
   * path: buildMmyFeaturesPath
   * api: https://qa-21-www.edmunds.com/api/vehicle/v4/makes/honda/models/civic/submodels/sedan/years/2018/styles/features-specs?pageNum=1&pageSize=3
   */
  {
    path: 'features.makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].styles',
    resolve({ make, model, year, submodel }, context) {
      return resolveStylesFeatures(
        `/vehicle/v4/makes/${make}/models/${model}/submodels/${submodel}/years/${year}/styles/features-specs?pageNum=1&pageSize=3`,
        context
      );
    },
  },
  /**
   * @return PropTypes.arrayOf(FeatureSpecsEntities.FeatureStyleEntity)
   *
   * path: buildPartialFeaturesPath
   * api: https://qa-11-www.edmunds.com/api/vehicle/v4/makes/honda/models/civic/submodels/sedan/years/2018/styles/features-specs?pageNum=1&pageSize=50&fields=totalSeating,price.baseMSRP,features.Fuel.Combined MPG,features.Drive Train.Transmission,features.Engine.Base engine type,features.Engine.Horsepower
   */
  {
    path: 'makes["{make}"].models["{model}"].submodels["{submodel}"].years["{year}"].stylesPartialFeaturesSpecs',
    resolve({ make, model, year, submodel }, context) {
      const url = `/vehicle/v4/makes/${make}/models/${model}/submodels/${submodel}/years/${year}/styles/features-specs?pageNum=1&pageSize=50&fields=${FEATURES_SUBSET}`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(result => (result && result.results) || []);
    },
  },
  /**
   * Takes a styleId and returns the corresponding features.
   * @return FeatureSpecsEntities.FeatureStyleEntity
   *
   * path: FeatureSpecsPaths.buildStyleWithFeaturesPath
   * api: https://qa-11-www.edmunds.com/api/vehicle/v4/styles/401741629/features-specs
   * @deprecated use buildVehicleStylePath instead
   */
  {
    path: 'styles["{styleId}"]',
    resolve({ styleId }, context) {
      const url = `/vehicle/v4/styles/${styleId}/features-specs`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(response => {
          if (response && response.results) {
            return response.results; // this will be a single style (not an array)
          }
          return null;
        });
    },
  },
  /**
   * Takes a styleId and returns the corresponding features.
   * @return FeatureSpecsEntities.FeatureStyleEntity
   *
   * path: FeatureSpecsPaths.buildStyleWithFeaturesPath
   * api: https://qa-11-www.edmunds.com/api/vehicle/v4/styles/401741629/features-specs
   * @deprecated use FeatureSpecsPaths.buildStyleFeaturesPath instead
   */
  {
    path: 'stylesWithFeatures["{styleId}"]',
    async resolve({ styleId }, context) {
      const style = await context.resolveValue(`styles["${styleId}"]`);
      if (style && style.features) {
        return { $ref: `#/styles/${styleId}` };
      }

      const response = await withMetrics(EdmundsAPI, context).fetchJson(`/vehicle/v4/styles/${styleId}/features-specs`);
      if (response && response.results) {
        await Promise.all([
          context.updateValue(`styles["${styleId}"].color`, response.results.color),
          context.updateValue(`styles["${styleId}"].totalSeating`, response.results.totalSeating),
          context.updateValue(`styles["${styleId}"].features`, response.results.features),
        ]);
      }

      return { $ref: `#/styles/${styleId}` };
    },
  },
  /**
   * @see FeatureSpecsPaths.buildStyleFeaturesPath
   */
  {
    path: 'features.styles["{styleId}"]',
    resolve({ styleId }, context) {
      const url = `/vehicle/v4/styles/${styleId}/features-specs`;
      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(response => {
          if (response && response.results) {
            return response.results; // this will be a single style (not an array)
          }
          return null;
        });
    },
  },
  /**
   * Example: https://www.edmunds.com/gateway/api/vehicle/v3/modelYears?makeNiceId=toyota&publicationStates=NEW,NEW_USED&fields=makeName,makeNiceName,modelName,modelNiceName,year,publicationStates,edTypeCategories&sortby=year%3ADESC&pageSize=1000&pageNum=1
   * @see buildModelYearsPath
   */
  {
    path: 'makes["{make}"].modelYears',
    resolve({ make }, context) {
      const url = `/vehicle/v3/modelYears?makeNiceId=${make}&publicationStates=NEW,NEW_USED&fields=makeName,makeNiceName,modelName,modelNiceName,year,publicationStates,edTypeCategories&sortby=year%3ADESC&pageSize=1000&pageNum=1`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ results }) => results);
    },
  },
  /**
   * https://qa-11-www.edmunds.com/api/vehicle/v3/modelYears?makeNiceName=honda&modelNiceName=accord&year=2019&fields=newDefaultStyle.id
   * @returns {Object}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].baseStyle',
    resolve({ makeSlug, modelSlug, year }, context) {
      const url = `/vehicle/v3/modelYears?makeNiceId=${makeSlug}&modelNiceId=${modelSlug}&year=${year}&fields=newDefaultStyle.id`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ results }) => get(results, '[0]', null));
    },
  },
  /**
   * https://qa-11-www.edmunds.com/api/vehicle/v3/modelYears?makeNiceName=honda&modelNiceName=accord&year=2019&fields=usedDefaultStyle.id
   * @returns {Object}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].usedBaseStyle',
    resolve({ makeSlug, modelSlug, year }, context) {
      const url = `/vehicle/v3/modelYears?makeNiceId=${makeSlug}&modelNiceId=${modelSlug}&year=${year}&fields=usedDefaultStyle.id`;

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(({ results }) => results && results[0]);
    },
  },
  /**
   * Returns defaultYear from query string. Otherwise uses API
   * Example: https://qa-21-www.edmunds.com/gateway/api/coreresearch/v2/defaultyear/honda/civic
   * @see buildMakeModelDefaultYear
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].defaultYear',
    async resolve({ makeSlug, modelSlug }, context) {
      const url = await getDefaultYearUrl({ context, makeSlug, modelSlug });

      return withMetrics(EdmundsAPI, context)
        .fetchJson(url)
        .then(years => {
          if (!years) {
            return null;
          }

          return years.results.year || years.results.defaultYear;
        });
    },
  },
]);

const ColorEntity = PropTypes.shape({
  name: PropTypes.string.isRequired, // ex: "Champagne Frost Pearl"
  rgb: PropTypes.string.isRequired, // ex: "200,167,124"
});

const FeatureStyleEntity = PropTypes.shape({
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  price: PropTypes.shape({
    baseMsrp: PropTypes.number,
    baseInvoice: PropTypes.number,
  }),
  color: PropTypes.shape({
    EXTERIOR: PropTypes.arrayOf(ColorEntity),
    INTERIOR: PropTypes.arrayOf(ColorEntity),
  }),
  features: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]))
  ),
  totalSeating: PropTypes.number,
  styleEndDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const FeatureSpecsEntities = {
  ColorEntity,
  FeatureStyleEntity,
};
